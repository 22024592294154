@font-face {
  font-family: Flama;
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Flama/FlamaCondensed-Medium.woff2") format("woff2"), url("../fonts/Flama/FlamaCondensed-Medium.woff") format("woff"), url("../fonts/Flama/FlamaCondensed-Medium.ttf") format("truetype"); }

@font-face {
  font-family: "Flama";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Flama/FlamaCondensed-Book.woff2") format("woff2"), url("../fonts/Flama/FlamaCondensed-Book.woff") format("woff"), url("../fonts/Flama/FlamaCondensed-Book.ttf") format("truetype"); }

@font-face {
  font-family: "Plantin";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Plantin/PlantinMTStd-Regular.woff2") format("woff2"), url("../fonts/Plantin/PlantinMTStd-Regular.woff") format("woff"), url("../fonts/Plantin/PlantinMTStd-Regular.ttf") format("truetype"); }

@font-face {
  font-family: "Plantin";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Plantin/PlantinMTStd-Light.woff2") format("woff2"), url("../fonts/Plantin/PlantinMTStd-Light.woff") format("woff"), url("../fonts/Plantin/PlantinMTStd-Light.ttf") format("truetype"); }

@font-face {
  font-family: "Plantin";
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Plantin/PlantinMTStd-Bold.woff2") format("woff2"), url("../fonts/Plantin/PlantinMTStd-Bold.woff") format("woff"), url("../fonts/Plantin/PlantinMTStd-Bold.ttf") format("truetype"); }

/* Colors Neuhaus */
/* Fonts Neuhaus */
.selectric-wrapper {
  position: relative;
  cursor: pointer; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 10px;
    font-size: 12px;
    line-height: 38px;
    color: #444;
    height: 38px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: #F8f8f8;
    color: #BBB;
    text-align: center;
    font: 0/0 a;
    *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial; }
    .selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #BBB;
      border-bottom: none; }

.selectric-focus .selectric {
  border-color: #aaaaaa; }

.selectric-hover .selectric {
  border-color: #c4c4c4; }
  .selectric-hover .selectric .button {
    color: #a2a2a2; }
    .selectric-hover .selectric .button:after {
      border-top-color: #a2a2a2; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #c4c4c4; }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  -webkit-box-shadow: 0 0 10px -6px;
          box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #666;
    cursor: pointer; }
    .selectric-items li.selected {
      background: #E0E0E0;
      color: #444; }
    .selectric-items li.highlighted {
      background: #D0D0D0;
      color: #444; }
    .selectric-items li:hover {
      background: #D5D5D5;
      color: #444; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

:root {
  --blue: #0070d2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #b14350;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #19b200;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #503223;
  --secondary: #cf7c58;
  --success: #19b200;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #b14350;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 544px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --breakpoint-xxxl: 1680px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f9f9f9; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #444;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1e1e1e;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ccc; }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f9f9f9;
  border: 1px solid #dee2e6;
  border-radius: 0.1875rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.1875rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 940px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1680px; } }

.container-fluid, .container-md, .container-lg, .container-xl, .container-xxl, .hero .page-title {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 769px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 940px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1440px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl, .hero .page-title {
    max-width: 1680px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto, .col-xxxl-1, .col-xxxl-2, .col-xxxl-3, .col-xxxl-4, .col-xxxl-5, .col-xxxl-6, .col-xxxl-7, .col-xxxl-8, .col-xxxl-9, .col-xxxl-10, .col-xxxl-11, .col-xxxl-12, .col-xxxl,
.col-xxxl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 544px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1680px) {
  .col-xxxl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xxxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xxxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xxxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xxxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xxxl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xxxl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xxxl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xxxl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xxxl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xxxl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xxxl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xxxl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xxxl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xxxl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xxxl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xxxl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xxxl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xxxl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xxxl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xxxl-0 {
    margin-left: 0; }
  .offset-xxxl-1 {
    margin-left: 8.33333%; }
  .offset-xxxl-2 {
    margin-left: 16.66667%; }
  .offset-xxxl-3 {
    margin-left: 25%; }
  .offset-xxxl-4 {
    margin-left: 33.33333%; }
  .offset-xxxl-5 {
    margin-left: 41.66667%; }
  .offset-xxxl-6 {
    margin-left: 50%; }
  .offset-xxxl-7 {
    margin-left: 58.33333%; }
  .offset-xxxl-8 {
    margin-left: 66.66667%; }
  .offset-xxxl-9 {
    margin-left: 75%; }
  .offset-xxxl-10 {
    margin-left: 83.33333%; }
  .offset-xxxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cec6c1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a4948d; }

.table-hover .table-primary:hover {
  background-color: #c3b9b3; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c3b9b3; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f2dad0; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #e6bba8; }

.table-hover .table-secondary:hover {
  background-color: #eccabc; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #eccabc; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bfe9b8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #87d77a; }

.table-hover .table-success:hover {
  background-color: #ade3a5; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #ade3a5; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #e9cace; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #d69da4; }

.table-hover .table-danger:hover {
  background-color: #e2b8bd; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e2b8bd; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ccc; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 543.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

@media (max-width: 1679.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #a9694a;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #19b200; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(25, 178, 0, 0.9);
  border-radius: 0.1875rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #19b200;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2319b200' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #19b200;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #19b200;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2319b200' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #19b200;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #19b200; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #19b200; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #19b200; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #20e500;
  background-color: #20e500; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #19b200; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #19b200; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #19b200;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.25); }

.invalid-feedback, .form-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #b14350; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(177, 67, 80, 0.9);
  border-radius: 0.1875rem; }

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .form-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .form-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #b14350;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23b14350' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b14350' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #b14350;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #b14350;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23b14350' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23b14350' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #b14350;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #b14350; }

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .form-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .form-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #b14350; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #b14350; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #c4636e;
  background-color: #c4636e; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b14350; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #b14350; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #b14350;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25);
          box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 544px) {
    .form-inline label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .adyen-checkout__button--pay {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .adyen-checkout__button--pay {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover, .adyen-checkout__button--pay:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .adyen-checkout__button--pay:focus, .btn.focus, .focus.adyen-checkout__button--pay {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
  .btn.disabled, .disabled.adyen-checkout__button--pay, .btn:disabled, .adyen-checkout__button--pay:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled), .adyen-checkout__button--pay:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled, a.disabled.adyen-checkout__button--pay,
fieldset:disabled a.btn,
fieldset:disabled a.adyen-checkout__button--pay {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #503223;
  border-color: #503223; }
  .btn-primary:hover {
    color: #fff;
    background-color: #352117;
    border-color: #2d1c13; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #352117;
    border-color: #2d1c13;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(106, 81, 68, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(106, 81, 68, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #503223;
    border-color: #503223; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2d1c13;
    border-color: #241610; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(106, 81, 68, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(106, 81, 68, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #cf7c58;
  border-color: #cf7c58; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #c6653a;
    border-color: #be5f36; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #c6653a;
    border-color: #be5f36;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(214, 144, 113, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(214, 144, 113, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #cf7c58;
    border-color: #cf7c58; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #be5f36;
    border-color: #b45a34; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(214, 144, 113, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(214, 144, 113, 0.5); }

.btn-success {
  color: #fff;
  background-color: #19b200;
  border-color: #19b200; }
  .btn-success:hover {
    color: #fff;
    background-color: #148c00;
    border-color: #127f00; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #148c00;
    border-color: #127f00;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(60, 190, 38, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(60, 190, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #19b200;
    border-color: #19b200; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #127f00;
    border-color: #107200; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(60, 190, 38, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(60, 190, 38, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #b14350;
  border-color: #b14350; }
  .btn-danger:hover {
    color: #fff;
    background-color: #953843;
    border-color: #8c353f; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #953843;
    border-color: #8c353f;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 95, 106, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(189, 95, 106, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #b14350;
    border-color: #b14350; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #8c353f;
    border-color: #83313b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(189, 95, 106, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(189, 95, 106, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #503223;
  border-color: #503223; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #503223;
    border-color: #503223; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #503223;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #503223;
    border-color: #503223; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.5); }

.btn-outline-secondary {
  color: #cf7c58;
  border-color: #cf7c58; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #cf7c58;
    border-color: #cf7c58; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 124, 88, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(207, 124, 88, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #cf7c58;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #cf7c58;
    border-color: #cf7c58; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 124, 88, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(207, 124, 88, 0.5); }

.btn-outline-success {
  color: #19b200;
  border-color: #19b200; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #19b200;
    border-color: #19b200; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #19b200;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #19b200;
    border-color: #19b200; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #b14350;
  border-color: #b14350; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #b14350;
    border-color: #b14350; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #b14350;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #b14350;
    border-color: #b14350; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
              box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #444;
  text-decoration: none; }
  .btn-link:hover {
    color: #1e1e1e;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .adyen-checkout__button--pay {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .adyen-checkout__button--pay {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 544px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1680px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #503223; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .adyen-checkout__button--pay,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .adyen-checkout__button--pay {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto; }
    .btn-group > .btn:hover, .btn-group > .adyen-checkout__button--pay:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .adyen-checkout__button--pay:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .adyen-checkout__button--pay:focus, .btn-group > .btn:active, .btn-group > .adyen-checkout__button--pay:active, .btn-group > .btn.active, .btn-group > .active.adyen-checkout__button--pay,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .adyen-checkout__button--pay:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .adyen-checkout__button--pay:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.adyen-checkout__button--pay {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .btn-group > .adyen-checkout__button--pay:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .adyen-checkout__button--pay:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .adyen-checkout__button--pay {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .btn-group > .adyen-checkout__button--pay:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .adyen-checkout__button--pay {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .adyen-checkout__button--pay + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .adyen-checkout__button--pay + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .adyen-checkout__button--pay,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .adyen-checkout__button--pay:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .adyen-checkout__button--pay:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .adyen-checkout__button--pay {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .adyen-checkout__button--pay:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .adyen-checkout__button--pay {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .adyen-checkout__button--pay,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .adyen-checkout__button--pay {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .adyen-checkout__button--pay input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .adyen-checkout__button--pay input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .adyen-checkout__button--pay input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .adyen-checkout__button--pay input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .adyen-checkout__button--pay,
  .input-group-append .btn,
  .input-group-append .adyen-checkout__button--pay {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .adyen-checkout__button--pay:focus,
    .input-group-append .btn:focus,
    .input-group-append .adyen-checkout__button--pay:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .adyen-checkout__button--pay + .btn, .input-group-prepend .btn + .adyen-checkout__button--pay, .input-group-prepend .adyen-checkout__button--pay + .adyen-checkout__button--pay,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .adyen-checkout__button--pay + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .adyen-checkout__button--pay,
  .input-group-append .btn + .btn,
  .input-group-append .adyen-checkout__button--pay + .btn,
  .input-group-append .btn + .adyen-checkout__button--pay,
  .input-group-append .adyen-checkout__button--pay + .adyen-checkout__button--pay,
  .input-group-append .btn + .input-group-text,
  .input-group-append .adyen-checkout__button--pay + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .adyen-checkout__button--pay {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .adyen-checkout__button--pay,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .adyen-checkout__button--pay {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .adyen-checkout__button--pay,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .adyen-checkout__button--pay {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .adyen-checkout__button--pay,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .adyen-checkout__button--pay,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .adyen-checkout__button--pay:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .adyen-checkout__button--pay,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .adyen-checkout__button--pay,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .adyen-checkout__button--pay:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #503223;
    background-color: #503223; }
  .custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a9694a; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #bd8568;
    border-color: #bd8568; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.1875rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #503223;
  background-color: #503223; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(80, 50, 35, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(80, 50, 35, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(80, 50, 35, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(80, 50, 35, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #a9694a;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #a9694a;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.1875rem 0.1875rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      -webkit-box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
              box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #503223;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #bd8568; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #503223;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #bd8568; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #503223;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #bd8568; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 0 solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs .nav-link {
    border: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f9f9f9;
    border-color: #dee2e6 #dee2e6 #f9f9f9; }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.1875rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #503223; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl, .navbar .hero .page-title, .hero .navbar .page-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 543.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl, .hero .navbar-expand-sm > .page-title {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 544px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl, .hero .navbar-expand-sm > .page-title {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl, .hero .navbar-expand-md > .page-title {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl, .hero .navbar-expand-md > .page-title {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl, .hero .navbar-expand-lg > .page-title {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl, .hero .navbar-expand-lg > .page-title {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl, .hero .navbar-expand-xl > .page-title {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl, .hero .navbar-expand-xl > .page-title {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl, .hero .navbar-expand-xxl > .page-title {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl, .hero .navbar-expand-xxl > .page-title {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

@media (max-width: 1679.98px) {
  .navbar-expand-xxxl > .container,
  .navbar-expand-xxxl > .container-fluid, .navbar-expand-xxxl > .container-md, .navbar-expand-xxxl > .container-lg, .navbar-expand-xxxl > .container-xl, .navbar-expand-xxxl > .container-xxl, .hero .navbar-expand-xxxl > .page-title {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1680px) {
  .navbar-expand-xxxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
    .navbar-expand-xxxl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xxxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxxl > .container,
    .navbar-expand-xxxl > .container-fluid, .navbar-expand-xxxl > .container-md, .navbar-expand-xxxl > .container-lg, .navbar-expand-xxxl > .container-xl, .navbar-expand-xxxl > .container-xxl, .hero .navbar-expand-xxxl > .page-title {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; }
    .navbar-expand-xxxl .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
          flex-basis: auto; }
    .navbar-expand-xxxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl, .hero .navbar-expand > .page-title {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl, .hero .navbar-expand > .page-title {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.1875rem - 1px);
      border-top-right-radius: calc(0.1875rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.1875rem - 1px);
      border-bottom-left-radius: calc(0.1875rem - 1px); }

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.1875rem - 1px) calc(0.1875rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 544px) {
  .card-deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
              flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 544px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 0%;
              flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 544px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.1875rem; }

.breadcrumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.1875rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #444;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1e1e1e;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #503223;
  border-color: #503223; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .adyen-checkout__button--pay .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #503223; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #2d1c13; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(80, 50, 35, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #cf7c58; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #be5f36; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(207, 124, 88, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(207, 124, 88, 0.5); }

.badge-success {
  color: #fff;
  background-color: #19b200; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #127f00; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(25, 178, 0, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #b14350; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #8c353f; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(177, 67, 80, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.1875rem; }
  @media (min-width: 544px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2a1a12;
  background-color: #dcd6d3;
  border-color: #cec6c1; }
  .alert-primary hr {
    border-top-color: #c3b9b3; }
  .alert-primary .alert-link {
    color: #060403; }

.alert-secondary {
  color: #6c402e;
  background-color: #f5e5de;
  border-color: #f2dad0; }
  .alert-secondary hr {
    border-top-color: #eccabc; }
  .alert-secondary .alert-link {
    color: #482b1f; }

.alert-success {
  color: #0d5d00;
  background-color: #d1f0cc;
  border-color: #bfe9b8; }
  .alert-success hr {
    border-top-color: #ade3a5; }
  .alert-success .alert-link {
    color: #062a00; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #5c232a;
  background-color: #efd9dc;
  border-color: #e9cace; }
  .alert-danger hr {
    border-top-color: #e2b8bd; }
  .alert-danger .alert-link {
    color: #371519; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.1875rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #503223;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.1875rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #503223;
    border-color: #503223; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 544px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1680px) {
  .list-group-horizontal-xxxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xxxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #2a1a12;
  background-color: #cec6c1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2a1a12;
    background-color: #c3b9b3; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #2a1a12;
    border-color: #2a1a12; }

.list-group-item-secondary {
  color: #6c402e;
  background-color: #f2dad0; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #6c402e;
    background-color: #eccabc; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #6c402e;
    border-color: #6c402e; }

.list-group-item-success {
  color: #0d5d00;
  background-color: #bfe9b8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0d5d00;
    background-color: #ade3a5; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0d5d00;
    border-color: #0d5d00; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #5c232a;
  background-color: #e9cace; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #5c232a;
    background-color: #e2b8bd; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #5c232a;
    border-color: #5c232a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 544px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.1875rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.1875rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.1875rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #503223 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2d1c13 !important; }

.bg-secondary {
  background-color: #cf7c58 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #be5f36 !important; }

.bg-success {
  background-color: #19b200 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #127f00 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #b14350 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #8c353f !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #503223 !important; }

.border-secondary {
  border-color: #cf7c58 !important; }

.border-success {
  border-color: #19b200 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #b14350 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.1875rem !important; }

.rounded {
  border-radius: 0.1875rem !important; }

.rounded-top {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important; }

.rounded-right {
  border-top-right-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

.rounded-left {
  border-top-left-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

.rounded-lg {
  border-radius: 0.1875rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 544px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1680px) {
  .d-xxxl-none {
    display: none !important; }
  .d-xxxl-inline {
    display: inline !important; }
  .d-xxxl-inline-block {
    display: inline-block !important; }
  .d-xxxl-block {
    display: block !important; }
  .d-xxxl-table {
    display: table !important; }
  .d-xxxl-table-row {
    display: table-row !important; }
  .d-xxxl-table-cell {
    display: table-cell !important; }
  .d-xxxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xxxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 544px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1680px) {
  .flex-xxxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xxxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xxxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xxxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xxxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xxxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xxxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xxxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xxxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xxxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xxxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xxxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xxxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xxxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xxxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xxxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xxxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xxxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xxxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xxxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xxxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xxxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xxxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xxxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xxxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xxxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xxxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xxxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xxxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xxxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xxxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xxxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xxxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xxxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 544px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

@media (min-width: 1680px) {
  .float-xxxl-left {
    float: left !important; }
  .float-xxxl-right {
    float: right !important; }
  .float-xxxl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

@media (min-width: 1680px) {
  .m-xxxl-0 {
    margin: 0 !important; }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important; }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important; }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important; }
  .m-xxxl-1 {
    margin: 0.25rem !important; }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxxl-2 {
    margin: 0.5rem !important; }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxxl-3 {
    margin: 1rem !important; }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important; }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important; }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important; }
  .m-xxxl-4 {
    margin: 1.5rem !important; }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxxl-5 {
    margin: 3rem !important; }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important; }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important; }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important; }
  .p-xxxl-0 {
    padding: 0 !important; }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important; }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important; }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important; }
  .p-xxxl-1 {
    padding: 0.25rem !important; }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxxl-2 {
    padding: 0.5rem !important; }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxxl-3 {
    padding: 1rem !important; }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important; }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important; }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important; }
  .p-xxxl-4 {
    padding: 1.5rem !important; }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxxl-5 {
    padding: 3rem !important; }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important; }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important; }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important; }
  .m-xxxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxxl-n3 {
    margin: -1rem !important; }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1rem !important; }
  .m-xxxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxxl-n5 {
    margin: -3rem !important; }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -3rem !important; }
  .m-xxxl-auto {
    margin: auto !important; }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important; }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important; }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important; }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1680px) {
  .text-xxxl-left {
    text-align: left !important; }
  .text-xxxl-right {
    text-align: right !important; }
  .text-xxxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #503223 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1b110c !important; }

.text-secondary {
  color: #cf7c58 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #aa5531 !important; }

.text-success {
  color: #19b200 !important; }

a.text-success:hover, a.text-success:focus {
  color: #0e6600 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #b14350 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #7a2e37 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important; }
  a:not(.btn):not(.adyen-checkout__button--pay) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ccc; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ccc; } }

.carousel .icon-prev,
.carousel .icon-next {
  background-color: #fff;
  font-size: 1.875em;
  height: 3rem;
  padding-top: 0.24em;
  width: 3rem; }
  .carousel .icon-prev::before,
  .carousel .icon-next::before {
    color: black;
    font-family: 'FontAwesome'; }

.carousel .icon-prev::before {
  content: '\f104'; }

.carousel .icon-next::before {
  content: '\f105'; }

.carousel .carousel-control-prev {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

.carousel .carousel-control-next {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.nav-tabs {
  border-bottom: 1px solid #ccc; }
  .nav-tabs .nav-link {
    font-size: 1.1rem;
    color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.active {
      border-bottom: 0.188em solid #503223; }

.card {
  margin-bottom: 1em; }

.card-header h4 {
  margin-bottom: 0; }

.modal .modal-body {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }

dt {
  color: #495057;
  font-weight: normal; }

.custom-checkbox .custom-control-label::before {
  border: 1px solid black;
  background: #f9f9f9;
  /* For browsers that do not support gradients */
  background: -webkit-gradient(linear, left top, left bottom, from(#f9f9f9), to(#ccc));
  background: linear-gradient(#f9f9f9, #ccc);
  /* Standard syntax */ }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-label::before {
  border: 1px solid black;
  background: #ccc;
  /* For browsers that do not support gradients */
  background: -webkit-gradient(linear, left top, left bottom, from(#ccc), to(#666));
  background: linear-gradient(#ccc, #666);
  /* Standard syntax */ }

.form-control.is-invalid {
  background-image: none; }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1200px) and (max-width: 1439.98px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (min-width: 1440px) and (max-width: 1679.98px) {
  .pull-left-xxl {
    float: left; }
  .pull-right-xxl {
    float: right; } }

@media (min-width: 1680px) {
  .pull-left-xxxl {
    float: left; }
  .pull-right-xxxl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-xl-down {
    display: none !important; } }

@media (max-width: 1679.98px) {
  .hidden-xxl-down {
    display: none !important; } }

.hidden-xxxl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(../fonts/flags//4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(../fonts/flags//4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ae.svg); }

.flag-icon-af {
  background-image: url(../fonts/flags//4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/af.svg); }

.flag-icon-ag {
  background-image: url(../fonts/flags//4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(../fonts/flags//4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ai.svg); }

.flag-icon-al {
  background-image: url(../fonts/flags//4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/al.svg); }

.flag-icon-am {
  background-image: url(../fonts/flags//4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/am.svg); }

.flag-icon-ao {
  background-image: url(../fonts/flags//4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(../fonts/flags//4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(../fonts/flags//4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ar.svg); }

.flag-icon-as {
  background-image: url(../fonts/flags//4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/as.svg); }

.flag-icon-at {
  background-image: url(../fonts/flags//4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/at.svg); }

.flag-icon-au {
  background-image: url(../fonts/flags//4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/au.svg); }

.flag-icon-aw {
  background-image: url(../fonts/flags//4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(../fonts/flags//4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ax.svg); }

.flag-icon-az {
  background-image: url(../fonts/flags//4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/az.svg); }

.flag-icon-ba {
  background-image: url(../fonts/flags//4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(../fonts/flags//4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(../fonts/flags//4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bd.svg); }

.flag-icon-be {
  background-image: url(../fonts/flags//4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/be.svg); }

.flag-icon-bf {
  background-image: url(../fonts/flags//4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(../fonts/flags//4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(../fonts/flags//4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(../fonts/flags//4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(../fonts/flags//4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(../fonts/flags//4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(../fonts/flags//4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(../fonts/flags//4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(../fonts/flags//4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(../fonts/flags//4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bq.svg); }

.flag-icon-br {
  background-image: url(../fonts/flags//4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/br.svg); }

.flag-icon-bs {
  background-image: url(../fonts/flags//4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(../fonts/flags//4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(../fonts/flags//4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(../fonts/flags//4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bw.svg); }

.flag-icon-by {
  background-image: url(../fonts/flags//4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/by.svg); }

.flag-icon-bz {
  background-image: url(../fonts/flags//4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(../fonts/flags//4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(../fonts/flags//4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(../fonts/flags//4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(../fonts/flags//4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(../fonts/flags//4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(../fonts/flags//4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(../fonts/flags//4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(../fonts/flags//4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(../fonts/flags//4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(../fonts/flags//4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(../fonts/flags//4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cn.svg); }

.flag-icon-co {
  background-image: url(../fonts/flags//4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/co.svg); }

.flag-icon-cr {
  background-image: url(../fonts/flags//4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(../fonts/flags//4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(../fonts/flags//4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(../fonts/flags//4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(../fonts/flags//4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(../fonts/flags//4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(../fonts/flags//4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cz.svg); }

.flag-icon-de {
  background-image: url(../fonts/flags//4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/de.svg); }

.flag-icon-dj {
  background-image: url(../fonts/flags//4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(../fonts/flags//4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(../fonts/flags//4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dm.svg); }

.flag-icon-do {
  background-image: url(../fonts/flags//4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/do.svg); }

.flag-icon-dz {
  background-image: url(../fonts/flags//4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(../fonts/flags//4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(../fonts/flags//4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(../fonts/flags//4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(../fonts/flags//4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eh.svg); }

.flag-icon-er {
  background-image: url(../fonts/flags//4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/er.svg); }

.flag-icon-es {
  background-image: url(../fonts/flags//4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es.svg); }

.flag-icon-et {
  background-image: url(../fonts/flags//4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/et.svg); }

.flag-icon-fi {
  background-image: url(../fonts/flags//4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(../fonts/flags//4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(../fonts/flags//4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(../fonts/flags//4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(../fonts/flags//4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(../fonts/flags//4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(../fonts/flags//4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(../fonts/flags//4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(../fonts/flags//4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(../fonts/flags//4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(../fonts/flags//4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(../fonts/flags//4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(../fonts/flags//4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(../fonts/flags//4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(../fonts/flags//4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(../fonts/flags//4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(../fonts/flags//4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(../fonts/flags//4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(../fonts/flags//4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(../fonts/flags//4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(../fonts/flags//4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(../fonts/flags//4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(../fonts/flags//4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(../fonts/flags//4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(../fonts/flags//4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(../fonts/flags//4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(../fonts/flags//4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(../fonts/flags//4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(../fonts/flags//4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(../fonts/flags//4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(../fonts/flags//4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hu.svg); }

.flag-icon-id {
  background-image: url(../fonts/flags//4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/id.svg); }

.flag-icon-ie {
  background-image: url(../fonts/flags//4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ie.svg); }

.flag-icon-il {
  background-image: url(../fonts/flags//4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/il.svg); }

.flag-icon-im {
  background-image: url(../fonts/flags//4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/im.svg); }

.flag-icon-in {
  background-image: url(../fonts/flags//4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/in.svg); }

.flag-icon-io {
  background-image: url(../fonts/flags//4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/io.svg); }

.flag-icon-iq {
  background-image: url(../fonts/flags//4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(../fonts/flags//4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ir.svg); }

.flag-icon-is {
  background-image: url(../fonts/flags//4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/is.svg); }

.flag-icon-it {
  background-image: url(../fonts/flags//4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/it.svg); }

.flag-icon-je {
  background-image: url(../fonts/flags//4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/je.svg); }

.flag-icon-jm {
  background-image: url(../fonts/flags//4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(../fonts/flags//4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(../fonts/flags//4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(../fonts/flags//4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(../fonts/flags//4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(../fonts/flags//4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(../fonts/flags//4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ki.svg); }

.flag-icon-km {
  background-image: url(../fonts/flags//4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/km.svg); }

.flag-icon-kn {
  background-image: url(../fonts/flags//4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(../fonts/flags//4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(../fonts/flags//4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(../fonts/flags//4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(../fonts/flags//4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(../fonts/flags//4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kz.svg); }

.flag-icon-la {
  background-image: url(../fonts/flags//4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/la.svg); }

.flag-icon-lb {
  background-image: url(../fonts/flags//4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(../fonts/flags//4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lc.svg); }

.flag-icon-li {
  background-image: url(../fonts/flags//4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/li.svg); }

.flag-icon-lk {
  background-image: url(../fonts/flags//4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(../fonts/flags//4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(../fonts/flags//4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(../fonts/flags//4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(../fonts/flags//4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(../fonts/flags//4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(../fonts/flags//4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(../fonts/flags//4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(../fonts/flags//4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mc.svg); }

.flag-icon-md {
  background-image: url(../fonts/flags//4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/md.svg); }

.flag-icon-me {
  background-image: url(../fonts/flags//4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/me.svg); }

.flag-icon-mf {
  background-image: url(../fonts/flags//4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(../fonts/flags//4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(../fonts/flags//4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(../fonts/flags//4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(../fonts/flags//4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(../fonts/flags//4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(../fonts/flags//4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(../fonts/flags//4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(../fonts/flags//4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(../fonts/flags//4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(../fonts/flags//4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(../fonts/flags//4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(../fonts/flags//4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(../fonts/flags//4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(../fonts/flags//4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(../fonts/flags//4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(../fonts/flags//4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mx.svg); }

.flag-icon-my {
  background-image: url(../fonts/flags//4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/my.svg); }

.flag-icon-mz {
  background-image: url(../fonts/flags//4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mz.svg); }

.flag-icon-na {
  background-image: url(../fonts/flags//4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/na.svg); }

.flag-icon-nc {
  background-image: url(../fonts/flags//4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(../fonts/flags//4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(../fonts/flags//4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(../fonts/flags//4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(../fonts/flags//4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(../fonts/flags//4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nl.svg); }

.flag-icon-no {
  background-image: url(../fonts/flags//4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/no.svg); }

.flag-icon-np {
  background-image: url(../fonts/flags//4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/np.svg); }

.flag-icon-nr {
  background-image: url(../fonts/flags//4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(../fonts/flags//4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(../fonts/flags//4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nz.svg); }

.flag-icon-om {
  background-image: url(../fonts/flags//4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/om.svg); }

.flag-icon-pa {
  background-image: url(../fonts/flags//4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(../fonts/flags//4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(../fonts/flags//4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(../fonts/flags//4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(../fonts/flags//4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(../fonts/flags//4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(../fonts/flags//4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(../fonts/flags//4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(../fonts/flags//4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(../fonts/flags//4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(../fonts/flags//4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(../fonts/flags//4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(../fonts/flags//4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pw.svg); }

.flag-icon-py {
  background-image: url(../fonts/flags//4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/py.svg); }

.flag-icon-qa {
  background-image: url(../fonts/flags//4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/qa.svg); }

.flag-icon-re {
  background-image: url(../fonts/flags//4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/re.svg); }

.flag-icon-ro {
  background-image: url(../fonts/flags//4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(../fonts/flags//4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(../fonts/flags//4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(../fonts/flags//4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(../fonts/flags//4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(../fonts/flags//4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(../fonts/flags//4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(../fonts/flags//4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sd.svg); }

.flag-icon-se {
  background-image: url(../fonts/flags//4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/se.svg); }

.flag-icon-sg {
  background-image: url(../fonts/flags//4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(../fonts/flags//4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sh.svg); }

.flag-icon-si {
  background-image: url(../fonts/flags//4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/si.svg); }

.flag-icon-sj {
  background-image: url(../fonts/flags//4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(../fonts/flags//4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(../fonts/flags//4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(../fonts/flags//4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(../fonts/flags//4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sn.svg); }

.flag-icon-so {
  background-image: url(../fonts/flags//4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/so.svg); }

.flag-icon-sr {
  background-image: url(../fonts/flags//4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(../fonts/flags//4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ss.svg); }

.flag-icon-st {
  background-image: url(../fonts/flags//4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/st.svg); }

.flag-icon-sv {
  background-image: url(../fonts/flags//4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(../fonts/flags//4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(../fonts/flags//4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(../fonts/flags//4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(../fonts/flags//4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tc.svg); }

.flag-icon-td {
  background-image: url(../fonts/flags//4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/td.svg); }

.flag-icon-tf {
  background-image: url(../fonts/flags//4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(../fonts/flags//4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tg.svg); }

.flag-icon-th {
  background-image: url(../fonts/flags//4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/th.svg); }

.flag-icon-tj {
  background-image: url(../fonts/flags//4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(../fonts/flags//4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(../fonts/flags//4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(../fonts/flags//4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(../fonts/flags//4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tn.svg); }

.flag-icon-to {
  background-image: url(../fonts/flags//4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/to.svg); }

.flag-icon-tr {
  background-image: url(../fonts/flags//4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(../fonts/flags//4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(../fonts/flags//4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(../fonts/flags//4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(../fonts/flags//4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(../fonts/flags//4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(../fonts/flags//4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ug.svg); }

.flag-icon-um {
  background-image: url(../fonts/flags//4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/um.svg); }

.flag-icon-us {
  background-image: url(../fonts/flags//4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/us.svg); }

.flag-icon-uy {
  background-image: url(../fonts/flags//4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(../fonts/flags//4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uz.svg); }

.flag-icon-va {
  background-image: url(../fonts/flags//4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/va.svg); }

.flag-icon-vc {
  background-image: url(../fonts/flags//4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(../fonts/flags//4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(../fonts/flags//4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(../fonts/flags//4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(../fonts/flags//4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(../fonts/flags//4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(../fonts/flags//4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(../fonts/flags//4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(../fonts/flags//4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(../fonts/flags//4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/yt.svg); }

.flag-icon-za {
  background-image: url(../fonts/flags//4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/za.svg); }

.flag-icon-zm {
  background-image: url(../fonts/flags//4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(../fonts/flags//4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../fonts/flags//4x3/es-ct.svg); }
  .flag-icon-es-ct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../fonts/flags//4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../fonts/flags//4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../fonts/flags//4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../fonts/flags//4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../fonts/flags//4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../fonts/flags//4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/un.svg); }

html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background, .modal-background-page, .modal-background-consent, header.flyout-open .modal-background-flyout,
  header.flyout-open .modal-background-menu, header.menu-open .modal-background-flyout,
  header.menu-open .modal-background-menu {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: 3rem; }

.h2,
h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.25rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, footer .container-footer-navigation .footer-navigation .footer-navigation-urls li a, .minicart .cart .sub-total-label,
.minicart .cart .sub-total, .line-item-total-price, .font-heading, .header-flyout-content-title {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy, .cart .remove-btn,
.cart .remove-btn-lg {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

/* Custom container Neuhaus */
.container-xxl, .hero .page-title {
  width: 100%;
  max-width: 1680px; }
  @media (min-width: 1200px) {
    .container-xxl, .hero .page-title {
      padding-right: 40px;
      padding-left: 40px; }
      .container-xxl > .row, .hero .page-title > .row {
        margin-right: -40px;
        margin-left: -40px; }
        .container-xxl > .row > .col, .hero .page-title > .row > .col,
        .container-xxl > .row > [class*="col-"],
        .hero .page-title > .row > [class*="col-"] {
          padding-right: 40px;
          padding-left: 40px; } }
  .container-xxl .container-xxl-padding, .hero .page-title .container-xxl-padding {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 1200px) {
      .container-xxl .container-xxl-padding, .hero .page-title .container-xxl-padding {
        padding-left: 40px;
        padding-right: 40px; } }

/* Custom breakpoint classes: align-items-normal */
@media (min-width: 544px) {
  .align-items-sm-normal {
    -webkit-box-align: normal !important;
        -ms-flex-align: normal !important;
            align-items: normal !important; } }

@media (min-width: 769px) {
  .align-items-md-normal {
    -webkit-box-align: normal !important;
        -ms-flex-align: normal !important;
            align-items: normal !important; } }

@media (min-width: 992px) {
  .align-items-lg-normal {
    -webkit-box-align: normal !important;
        -ms-flex-align: normal !important;
            align-items: normal !important; } }

@media (min-width: 1200px) {
  .align-items-xl-normal {
    -webkit-box-align: normal !important;
        -ms-flex-align: normal !important;
            align-items: normal !important; } }

/* Custom breakpoint classes: width and height */
@media (min-width: 544px) {
  .w-sm-auto {
    width: auto !important; } }

@media (min-width: 544px) {
  .w-sm-100 {
    width: 100% !important; } }

@media (min-width: 544px) {
  .w-sm-75 {
    width: 75% !important; } }

@media (min-width: 769px) {
  .w-md-auto {
    width: auto !important; } }

@media (min-width: 769px) {
  .w-md-100 {
    width: 100% !important; } }

@media (min-width: 769px) {
  .w-md-75 {
    width: 75% !important; } }

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important; } }

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important; } }

@media (min-width: 992px) {
  .w-lg-75 {
    width: 75% !important; } }

@media (min-width: 1200px) {
  .w-xl-auto {
    width: auto !important; } }

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important; } }

@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75% !important; } }

/* Overwrite bootstraps' border to our own */
.border {
  border: 1px solid #ddd !important; }
  .border-bottom {
    border-bottom: 1px solid #ddd !important; }
  .border-top {
    border-top: 1px solid #ddd !important; }
  .border-left {
    border-left: 1px solid #ddd !important; }
  .border-right {
    border-right: 1px solid #ddd !important; }
  .border-dark-grey {
    border-color: #a5a5a5; }

.bg-grey {
  background-color: #ddd; }

.bg-light-grey {
  background-color: #efefef; }

.bg-dark-grey {
  background-color: #a5a5a5; }

.bg-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.bg-none {
  background: none; }

.bg-light-red {
  background-color: #ebd0d3; }

.text-dark-grey, .text-muted {
  color: #a5a5a5 !important; }

.text-tertiary {
  color: #7c655a !important; }

.text-underline {
  text-decoration: underline; }

.cursor-pointer {
  cursor: pointer; }

.object-fit-contain {
  -o-object-fit: contain;
     object-fit: contain; }

.form-control,
input[type="text"],
select,
button, .btn, .adyen-checkout__button--pay {
  height: 50px; }

@media (max-width: 768.98px) {
  .mobile-collapse-column {
    border-top: 1px solid #ccc; }
    .mobile-collapse-column:last-child {
      border-bottom: 1px solid #ccc; } }

@media (max-width: 768.98px) {
  .no-scroll {
    overflow: hidden; } }

.collapsing.no-animation {
  -webkit-transition: none;
  transition: none;
  display: none; }

.modal-background, .modal-background-page, .modal-background-consent, header.flyout-open .modal-background-flyout,
header.flyout-open .modal-background-menu, header.menu-open .modal-background-flyout,
header.menu-open .modal-background-menu {
  background-color: #2d2d2d;
  display: none;
  height: 100%;
  position: fixed;
  opacity: 0.75;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20; }

.modal-background-page {
  z-index: 2; }

.modal-background-consent {
  z-index: 80; }

input[placeholder] {
  text-overflow: ellipsis; }

.hide-order-discount {
  display: none; }

.hide-shipping-discount {
  display: none; }

.order-discount {
  color: #19b200; }

.shipping-discount {
  color: #19b200; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030; }
  .error-messaging .alert {
    width: 50%;
    margin: 1rem auto; }

.error-hero {
  background-image: url("../images/storelocator.jpg");
  margin-bottom: 0.625em; }

.error.continue-shopping {
  margin-bottom: 6.25em; }

.error-unassigned-category {
  color: #b14350; }

.skip {
  position: absolute;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
  background: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.card-header-custom {
  font-size: 1.5rem;
  margin-bottom: 0; }

.add-to-wishlist-messages,
.add-to-cart-messages {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 30; }

.external-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .external-login a {
    padding-left: 12px;
    padding-right: 12px;
    width: calc(50% - 8px);
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .external-login .oauth-google,
  .external-login .oauth-facebook {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #ddd;
    letter-spacing: unset; }
    .external-login .oauth-google span,
    .external-login .oauth-facebook span {
      font-family: "Arial", "Helvetica", sans-serif;
      font-size: 0.875rem;
      line-height: 17px;
      text-transform: capitalize;
      color: #2d2d2d; }

.fw-600 {
  font-weight: 600; }

@media (max-width: 543.98px) {
  .menu-toggleable-left.navbar-toggleable-xs {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xs.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 768.98px) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-sm.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-md.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1199.98px) {
  .menu-toggleable-left.navbar-toggleable-lg {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-lg.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1439.98px) {
  .menu-toggleable-left.navbar-toggleable-xl {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xl.in {
      min-width: 50%;
      left: 0; } }

@media (max-width: 1679.98px) {
  .menu-toggleable-left.navbar-toggleable-xxl {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%; }
    .menu-toggleable-left.navbar-toggleable-xxl.in {
      min-width: 50%;
      left: 0; } }

.menu-toggleable-left.navbar-toggleable-xxxl {
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  display: block;
  max-width: 100%; }
  .menu-toggleable-left.navbar-toggleable-xxxl.in {
    min-width: 50%;
    left: 0; }

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0; }
  @media (min-width: 769px) {
    .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
      margin-left: 0; }
    .navbar.bg-inverse .navbar-nav .nav-link {
      padding: 0.8rem;
      white-space: nowrap; } }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: #fff;
  color: #444; }

@media (min-width: 769px) {
  .nav-item > .nav-link {
    color: #fff; } }

@media (max-width: 768.98px) {
  .main-menu.menu-toggleable-left {
    background-color: #fff;
    z-index: 4; } }

.menu-toggleable-left .close-menu {
  padding: 15px;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }
  @media (min-width: 769px) {
    .menu-toggleable-left .close-menu {
      display: none; } }

.menu-toggleable-left .menu-group {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%; }

.menu-toggleable-left li > .close-menu {
  margin-right: 0;
  margin-top: -0.6rem;
  margin-left: 0; }

@media (max-width: 768.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: white !important;
    color: #222; } }

@media (max-width: 543.98px) {
  .menu-toggleable-left.in {
    right: 0;
    margin-right: 1.25em;
    /* 20/16 */ } }

@media (max-width: 768.98px) {
  .menu-toggleable-left.in .nav-item + .nav-item {
    border-top: 1px solid #eee; }
  .menu-toggleable-left.in .dropdown {
    display: block;
    position: static; }
  .menu-toggleable-left.in .dropdown-toggle {
    padding-left: 1rem; }
    .menu-toggleable-left.in .dropdown-toggle::after {
      border-top: 0.3em solid transparent;
      border-bottom: 0.3em solid transparent;
      border-left: 0.3em solid;
      position: absolute;
      right: 0.3em;
      margin-top: 0.55em; }
  .menu-toggleable-left.in .nav-item .nav-link {
    padding-left: 1rem; }
  .menu-toggleable-left.in .show > .dropdown-menu {
    left: 0; }
  .menu-toggleable-left.in .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    -webkit-transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block; } }

.multilevel-dropdown .dropdown-menu {
  top: 90%;
  border: 0;
  border-radius: 0; }
  @media (min-width: 769px) {
    .multilevel-dropdown .dropdown-menu {
      -webkit-box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
              box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4); } }

.multilevel-dropdown .dropdown-item.dropdown > .dropdown-toggle::after {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em; }

@media (min-width: 769px) {
  .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
    top: -0.65em;
    left: 99%; } }

.multilevel-dropdown .navbar > .close-menu > .back {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-left: 0.3 solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  margin-bottom: 0.125em; }

.multilevel-dropdown .dropdown-item {
  padding: 0 0 0 1em; }
  .multilevel-dropdown .dropdown-item .dropdown-link {
    display: block;
    padding: 0.425em 5em 0.425em 0; }
  .multilevel-dropdown .dropdown-item + .dropdown-item {
    border-top: 1px solid #eee; }
  .multilevel-dropdown .dropdown-item.top-category {
    font-weight: bold; }
    .multilevel-dropdown .dropdown-item.top-category > .nav-link {
      padding-left: 0; }

.header {
  position: relative; }

.navbar-header {
  height: 4.375em;
  /* 70/16 */ }
  .navbar-header .user,
  .navbar-header .country-selector,
  .navbar-header .search,
  .navbar-header .minicart {
    display: inline-block;
    margin: 1.125em 0 0 0.5em; }
  .navbar-header .user,
  .navbar-header .country-selector,
  .navbar-header .minicart,
  .navbar-header .navbar-toggler {
    line-height: 2.25em;
    /* 36/16 */
    height: auto; }
  .navbar-header .navbar-toggler {
    font-size: 1.6em;
    width: auto; }
  .navbar-header .user {
    position: relative; }
    .navbar-header .user .popover {
      position: absolute;
      display: none;
      padding: 1em;
      top: 85%;
      left: 0; }
      .navbar-header .user .popover a {
        white-space: nowrap;
        margin-bottom: 0.5em; }
      .navbar-header .user .popover::before {
        left: 1.5rem; }
      .navbar-header .user .popover::after {
        left: 1.5rem; }
      .navbar-header .user .popover.show {
        display: block; }

.brand {
  position: absolute;
  left: 50%;
  display: block;
  text-align: center; }
  .brand img {
    width: 100%; }
  @media (min-width: 992px) {
    .brand {
      width: 14.125em;
      /* 226/16 */
      margin-left: -7.0625em;
      /* 113/16 */
      padding-top: 0.5em; } }
  @media (max-width: 991.98px) {
    .brand {
      width: 4em;
      /* 64/16 */
      margin-left: -2em;
      padding-top: 0.8em; } }

.main-menu {
  background-color: #444; }
  .main-menu .navbar .close-menu button,
  .main-menu .navbar .close-button button {
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }

.header-banner {
  background-color: #222;
  text-align: center;
  color: #fff; }
  .header-banner .close-button {
    width: 2.125em; }
    .header-banner .close-button .close {
      opacity: 1;
      color: #fff;
      width: 100%;
      height: 100%;
      background-color: #503223; }
  .header-banner .content {
    margin-right: 1.5em;
    padding-top: 0.3125em;
    padding-bottom: 0.3125em; }

.minicart {
  margin-top: 0.1875em;
  /* 3/16 */
  vertical-align: top; }
  .minicart .minicart-icon {
    font-size: 1.5em; }
  .minicart a.minicart-link:hover {
    text-decoration: none; }
  .minicart .minicart-quantity {
    background-color: #503223;
    border-radius: 50%;
    width: 1.25em;
    /* 20/16 */
    height: 1.25em;
    /* 20/16 */
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-size: 0.8125em;
    /* 13/16 */
    position: relative;
    top: -0.9375em;
    /* 15/16 */
    left: -0.9375em;
    /* 15/16 */
    color: #fff; }

a.normal {
  color: #503223;
  text-decoration: underline; }

.slide-up {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden; }
  .slide-up.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.dropdown-country-selector {
  margin-top: -0.0625em; }

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  text-align: center;
  display: none; }

.valid-cookie-warning {
  background-color: #0070d2;
  color: #fff;
  white-space: nowrap; }
  .valid-cookie-warning p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em; }

.navbar button:focus, .navbar button:active,
.main-menu button:focus,
.main-menu button:active,
.main-menu .nav-link:focus,
.main-menu .nav-link:active,
.main-menu .dropdown-link:focus,
.main-menu .dropdown-link:active {
  outline: none; }

.nav-link {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em;
  font-size: 1rem;
  font-weight: 300;
  padding: 0; }
  .nav-link-primary, .nav-link-primary:hover {
    color: #503223; }
  .nav-link-secondary, .nav-link-secondary:hover {
    color: #cf7c58; }

.main-menu {
  background-color: #fff;
  position: relative; }
  .main-menu .navbar {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap; }
    .main-menu .navbar .close-menu {
      -webkit-box-flex: 0;
          -ms-flex: none;
              flex: none; }
    .main-menu .navbar .menu-group {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .main-menu .navbar .menu-group .nav.navbar-nav {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; }
  .main-menu .navbar-nav {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .main-menu .navbar-nav .nav-item {
      padding: 12px 0; }
      @media (min-width: 769px) {
        .main-menu .navbar-nav .nav-item {
          padding: 0 15px; }
          header.sticky .main-menu .navbar-nav .nav-item {
            padding: 0; } }
    @media (min-width: 769px) and (min-width: 1200px) {
      header.sticky .main-menu .navbar-nav .nav-item {
        padding: 0 15px; } }
      @media (min-width: 769px) {
          .main-menu .navbar-nav .nav-item > .dropdown-menu {
            width: 100vw;
            max-width: 1680px; } }
      .main-menu .navbar-nav .nav-item > .nav-link {
        font-family: "Flama", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 1.5rem;
        -webkit-transition: font-size 0.2s ease-in-out;
        transition: font-size 0.2s ease-in-out;
        font-weight: 700;
        color: #503223; }
        @media (min-width: 769px) {
          .main-menu .navbar-nav .nav-item > .nav-link {
            font-size: 1.25rem; }
            header.sticky .main-menu .navbar-nav .nav-item > .nav-link {
              font-size: 1rem; } }
    @media (min-width: 769px) and (min-width: 992px) {
      header.sticky .main-menu .navbar-nav .nav-item > .nav-link {
        font-size: 1.25rem; } }
        .main-menu .navbar-nav .nav-item > .nav-link::after {
          margin-left: 15px;
          border: none;
          content: "";
          width: 11px;
          height: 11px;
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("../images/icons/arrow.svg"); }
      .main-menu .navbar-nav .nav-item.dropdown {
        position: initial; }
  .main-menu.multilevel-dropdown .dropdown-menu .category-image {
    display: none; }
  .main-menu.multilevel-dropdown .dropdown-menu .dropdown > .dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .main-menu.multilevel-dropdown .dropdown-menu .dropdown > .dropdown-menu .view-all-products-item > .dropdown-link {
      color: #cf7c58;
      text-decoration: underline; }
  .main-menu.multilevel-dropdown .dropdown-menu .view-all-products-item {
    margin: 30px 0 50px; }
  @media (min-width: 769px) {
    .main-menu.multilevel-dropdown .dropdown-menu .view-all-products-item {
      position: absolute;
      bottom: 0;
      display: none; }
    .main-menu.multilevel-dropdown .dropdown-menu .dropdown-item:nth-child(1) .view-all-products-item,
    .main-menu.multilevel-dropdown .dropdown-menu .category-image + .dropdown-item .view-all-products-item {
      display: block; } }
  .main-menu.multilevel-dropdown .dropdown-item {
    padding-left: 0; }
    .main-menu.multilevel-dropdown .dropdown-item + .dropdown-item {
      border-top: none; }
    .main-menu.multilevel-dropdown .dropdown-item .dropdown-link {
      font-family: "Flama", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      display: block;
      padding: 5px 0;
      margin-bottom: 15px;
      font-size: 1rem;
      font-weight: 700;
      color: #503223; }
    .main-menu.multilevel-dropdown .dropdown-item .dropdown-menu .dropdown-item {
      padding: 0; }
      .main-menu.multilevel-dropdown .dropdown-item .dropdown-menu .dropdown-item .dropdown-link {
        font-family: "Plantin", serif;
        text-transform: none;
        letter-spacing: 0.01em;
        font-weight: 300;
        color: #7c655a;
        margin: 0; }
    .main-menu.multilevel-dropdown .dropdown-item:hover, .main-menu.multilevel-dropdown .dropdown-item:focus {
      background: none; }
  @media (min-width: 769px) {
    .main-menu.multilevel-dropdown .dropdown-menu {
      border: 0;
      margin: 0;
      padding: 0 0 0 20px;
      -webkit-box-shadow: none;
              box-shadow: none;
      background: transparent;
      width: 100%; }
      .main-menu.multilevel-dropdown .dropdown-menu .dropdown-item {
        padding: 2em 0 2em 1em; }
      .main-menu.multilevel-dropdown .dropdown-menu.show {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        top: 100%;
        padding-top: 1rem; }
      .main-menu.multilevel-dropdown .dropdown-menu::before {
        content: "";
        background: #fff;
        position: absolute;
        width: 200vw;
        height: calc(100% - 1rem);
        left: 0;
        margin-left: -100vw;
        border-top: 1px solid #ddd;
        z-index: -1; }
      .main-menu.multilevel-dropdown .dropdown-menu .dropdown-toggle::after {
        display: none; }
      .main-menu.multilevel-dropdown .dropdown-menu .dropdown > .dropdown-menu {
        position: relative;
        padding: 0;
        top: 0;
        left: 0;
        height: 100%; }
        .main-menu.multilevel-dropdown .dropdown-menu .dropdown > .dropdown-menu::before, .main-menu.multilevel-dropdown .dropdown-menu .dropdown > .dropdown-menu::after {
          display: none; }
        .main-menu.multilevel-dropdown .dropdown-menu .dropdown > .dropdown-menu .dropdown-item:last-child {
          margin-bottom: 40px; } }
  @media (min-width: 992px) {
    .main-menu.multilevel-dropdown .dropdown-menu .category-image {
      display: block;
      max-width: 400px;
      -o-object-fit: cover;
         object-fit: cover; } }
  @media (max-width: 768.98px) {
    .main-menu.menu-toggleable-left {
      background-color: #fff;
      z-index: 30; } }
  .main-menu.menu-toggleable-left.in {
    min-width: 60%; }
    .main-menu.menu-toggleable-left.in > .navbar {
      height: 100%;
      -ms-flex-line-pack: start;
          align-content: flex-start; }
      .main-menu.menu-toggleable-left.in > .navbar .search {
        position: absolute;
        top: 0;
        right: 20px; }
        .main-menu.menu-toggleable-left.in > .navbar .search .site-search {
          width: 200px; }
    .main-menu.menu-toggleable-left.in .close-menu {
      background: transparent;
      border: none;
      padding: 8px 0 40px 20px;
      position: relative; }
    .main-menu.menu-toggleable-left.in .dropdown-menu {
      margin: 0; }
      .main-menu.menu-toggleable-left.in .dropdown-menu .close-menu {
        padding-left: 0;
        padding-top: 20px; }
    .main-menu.menu-toggleable-left.in .back .btn-back {
      background-color: #7c655a;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -25px; }
    .main-menu.menu-toggleable-left.in .navbar-nav,
    .main-menu.menu-toggleable-left.in .dropdown-menu {
      padding: 0 20px; }
    .main-menu.menu-toggleable-left.in .dropdown-toggle {
      position: relative; }
      .main-menu.menu-toggleable-left.in .dropdown-toggle::after {
        border: none;
        right: 20px;
        margin-top: 4px;
        content: "";
        width: 11px;
        height: 11px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../images/icons/arrow.svg"); }
    .main-menu.menu-toggleable-left.in .nav-item + .nav-item {
      border-top: none; }
    .main-menu.menu-toggleable-left.in .nav-item .nav-link {
      padding-left: 0; }
    .main-menu.menu-toggleable-left.in .dropdown-item.top-category {
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      margin-bottom: 15px; }
      .main-menu.menu-toggleable-left.in .dropdown-item.top-category > .nav-link,
      .main-menu.menu-toggleable-left.in .dropdown-item.top-category > .dropdown-link {
        font-family: "Flama", sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 1.5rem;
        font-weight: bold;
        color: #503223;
        padding: 0; }
    @media (max-width: 768.98px) {
      .main-menu.menu-toggleable-left.in .sidebar-links {
        margin-top: 2rem; } }
    .main-menu.menu-toggleable-left.in .sidebar-links .nav-item {
      border-top: 1px solid #ddd; }
      .main-menu.menu-toggleable-left.in .sidebar-links .nav-item > .nav-link {
        font-family: "Plantin", serif;
        text-transform: none;
        letter-spacing: 0.01em;
        font-size: 1rem;
        font-weight: normal; }
        .main-menu.menu-toggleable-left.in .sidebar-links .nav-item > .nav-link .icon {
          margin-right: 25px;
          vertical-align: sub; }

.header-preferences .header-currency {
  border-top: 1px solid #ddd; }

@media (min-width: 769px) {
  .header-preferences-mobile {
    display: none; } }

.header-preferences-mobile .selectric .label {
  padding-right: 0.5rem;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }

.header-preferences-desktop {
  display: none;
  position: absolute;
  left: 0;
  top: 0; }
  @media (min-width: 769px) {
    .header-preferences-desktop {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } }
  .header-preferences-desktop .selectric-wrapper .selectric {
    padding: 0; }
    .header-preferences-desktop .selectric-wrapper .selectric .label {
      padding: 0; }
  .header-preferences-desktop .selectric-wrapper .selectric-items li {
    padding: 0.5rem; }

.header-preferences .selectric-wrapper {
  width: auto; }
  .header-preferences .selectric-wrapper .selectric-items {
    border-color: #ddd; }
    .header-preferences .selectric-wrapper .selectric-items li {
      font-family: "Flama", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em; }
      .header-preferences .selectric-wrapper .selectric-items li .icon,
      .header-preferences .selectric-wrapper .selectric-items li .flag-icon {
        display: none; }
  .header-preferences .selectric-wrapper .selectric {
    border: none; }
    .header-preferences .selectric-wrapper .selectric .icon,
    .header-preferences .selectric-wrapper .selectric .flag-icon {
      display: none; }
    .header-preferences .selectric-wrapper .selectric .button {
      width: 0.75rem;
      right: 0; }
    .header-preferences .selectric-wrapper .selectric .label {
      font-family: "Flama", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em; }
  .header-preferences .selectric-wrapper select.form-control,
  .header-preferences .selectric-wrapper .selectric {
    height: auto; }

.page-header.sticky .header-preferences {
  display: none; }

footer .back-to-top {
  position: absolute;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  width: 40px;
  height: 40px;
  right: 15px;
  top: calc(50% - 20px); }
  @media (min-width: 1200px) {
    footer .back-to-top {
      right: 40px; } }

@media (min-width: 769px) {
  footer .secondary-content-container {
    background-color: #efefef; }
    footer .secondary-content-container .secondary-content {
      padding-bottom: 56px; } }

footer .icon.icon-logo-dated {
  height: 57px;
  width: auto; }

@media (max-width: 768.98px) {
  footer .container-xxl > .row > .col, footer .hero .page-title > .row > .col, .hero footer .page-title > .row > .col {
    padding: 0; } }

footer .container-footer-navigation .footer-navigation .footer-navigation-urls li {
  padding: 4px 0; }
  footer .container-footer-navigation .footer-navigation .footer-navigation-urls li a {
    color: #503223; }

@media (max-width: 768.98px) {
  footer .container-footer-navigation .footer-navigation .collapse,
  footer .container-footer-navigation .footer-navigation .collapsing {
    background-color: white;
    padding: 0 15px;
    margin: 0 -15px; } }

@media (min-width: 769px) {
  footer .container-footer-navigation .footer-navigation {
    padding-top: 56px;
    padding-bottom: 56px; } }

footer .social-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #ddd; }
  @media (min-width: 769px) {
    footer .social-content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: initial;
          -ms-flex-pack: initial;
              justify-content: initial;
      border: none; } }

footer .payment-content {
  border-right: 1px solid #ddd; }
  @media (min-width: 769px) {
    footer .payment-content {
      border: none; } }

footer .court-content {
  border-top: 1px solid #ddd; }
  @media (min-width: 769px) {
    footer .court-content {
      border: none; } }
  footer .court-content .icon.icon-royal-warrant {
    height: 81px;
    width: 66px; }

footer .url-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem 0; }
  footer .url-section a {
    font-size: 0.875rem;
    color: #a5a5a5;
    text-decoration: underline; }

@media (max-width: 768.98px) {
  footer.no-menu .info-content {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; } }

footer.no-menu .secondary-content-container {
  background-color: #efefef; }

footer .googleRecaptcha {
  bottom: -8px;
  height: 41px; }
  @media (max-width: 768.98px) {
    footer .googleRecaptcha {
      height: 20px;
      bottom: -10px; } }

@media (max-width: 768.98px) {
  footer .footer-navigation .selectric {
    border: none; }
  footer .footer-navigation select.form-control,
  footer .footer-navigation .selectric {
    padding: 0; }
  footer .footer-navigation .selectric-wrapper .selectric-items {
    border-color: #ddd; }
  footer .footer-navigation .footer-language {
    border-left: 1px solid #ddd; } }

.hero {
  background: #efefef;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #ddd; }
  .hero h1 {
    font-size: 2rem;
    margin-bottom: 0; }
  @media (min-width: 769px) {
    .hero {
      padding-top: 25px;
      padding-bottom: 25px;
      text-align: left; }
      .hero h1 {
        font-size: 2.5rem; } }

.notify {
  position: fixed;
  top: 0; }

.price {
  color: #222; }
  .price .strike-through {
    text-decoration: line-through;
    color: #999;
    margin-right: 0.938rem; }
  .price .starting,
  .price .range,
  .price .sales {
    font-weight: bold; }
  .price .tiered {
    color: #222; }
    .price .tiered table {
      border-top: 1px solid #ccc;
      margin: 0 auto; }
      .price .tiered table tr:nth-child(odd) {
        background-color: #eee; }
    .price .tiered td,
    .price .tiered span.price {
      font-size: 0.875rem;
      font-weight: bold; }
    .price .tiered td {
      padding: 0.313rem; }
      .price .tiered td.quantity {
        font-weight: normal;
        text-align: right; }
      .price .tiered td.value {
        text-align: left; }
    .price .tiered .table-header {
      font-size: 1.125rem;
      padding: 0.313rem; }
    .price .tiered .column-header {
      font-size: 1rem;
      padding: 0.313rem;
      font-weight: normal; }
    .price .tiered .column-header.quantity {
      text-align: right; }

#quickViewModal .quick-view-dialog {
  max-width: 50em; }
  #quickViewModal .quick-view-dialog .modal-header > .full-pdp-link {
    display: none; }
  #quickViewModal .quick-view-dialog .modal-footer {
    display: none; }
  #quickViewModal .quick-view-dialog .carousel .carousel-control {
    width: 15px; }
  #quickViewModal .quick-view-dialog .buttons .btn, #quickViewModal .quick-view-dialog .buttons .adyen-checkout__button--pay {
    width: 100%; }

#quickViewModal .primary-images {
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  opacity: 1; }

#quickViewModal:not(.shown) .primary-images {
  opacity: 0;
  height: 0;
  overflow: hidden; }

.product-tile .product-labels {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 75%;
  z-index: 1; }
  @media (min-width: 992px) {
    .product-tile .product-labels {
      max-width: 60%; } }
  .product-tile .product-labels .product-label {
    font-family: "Flama", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    background-color: #b14350;
    text-transform: uppercase;
    display: inline-block;
    color: white;
    font-size: 0.75rem;
    padding: 5px;
    margin-bottom: 4px; }
    @media (min-width: 769px) {
      .product-tile .product-labels .product-label {
        font-size: 1rem;
        padding: 8px; } }
  .product-tile .product-labels .product-label-new {
    font-family: "Flama", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    background-color: #503223;
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: white;
    font-size: 0.75rem;
    margin-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px; }
    @media (min-width: 769px) {
      .product-tile .product-labels .product-label-new {
        font-size: 1rem;
        padding: 8px; } }
  .product-tile .product-labels .personalise-label {
    background-color: #fff;
    border: 1px solid #ddd;
    border-color: #cf7c58;
    color: #cf7c58;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .product-tile .product-labels .personalise-label:hover {
      background-color: #cf7c58;
      color: #fff; }
    .product-tile .product-labels .personalise-label::after {
      display: inline-block;
      content: "";
      width: 10px;
      height: 10px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow-secondary.svg"); }
    .product-tile .product-labels .personalise-label:hover::after {
      display: inline-block;
      content: "";
      width: 10px;
      height: 10px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow-white.svg"); }

.product-tile {
  padding: 25px 15px; }
  .product-tile .plp-minimal-product-name {
    margin-top: 34px; }
  @media (min-width: 769px) {
    .product-tile {
      padding: 25px; } }
  .product-tile .image-container {
    position: relative;
    overflow: auto;
    text-align: center; }
    .product-tile .image-container a {
      display: block; }
      .product-tile .image-container a .pictureone {
        display: block; }
      .product-tile .image-container a .picturetwo {
        display: none; }
      .product-tile .image-container a:hover .pictureone {
        display: none; }
      .product-tile .image-container a:hover .picturetwo {
        display: block; }
      .product-tile .image-container a .tile-image {
        width: 100%;
        max-width: 200px;
        min-height: 200px;
        -o-object-fit: contain;
           object-fit: contain; }
        @media (min-width: 769px) {
          .product-tile .image-container a .tile-image {
            min-height: 250px; } }
        @media (min-width: 992px) {
          .product-tile .image-container a .tile-image {
            min-height: 300px; } }
  @media (min-width: 769px) {
    .product-tile .price {
      margin-bottom: 10px; } }
  .product-tile .price .sales .value {
    font-family: "Flama", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #cf7c58;
    font-size: 0.875rem;
    font-weight: 400; }
    @media (min-width: 769px) {
      .product-tile .price .sales .value {
        font-size: 1.5rem; } }
  .product-tile .pdp-link a {
    font-family: "Flama", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #503223;
    font-size: 0.875rem;
    font-weight: 700; }
    @media (min-width: 769px) {
      .product-tile .pdp-link a {
        font-size: 1.25rem; } }
  .product-tile .quickview {
    padding: 0;
    height: 40px;
    visibility: hidden;
    width: 40px;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    @media (max-width: 991.98px) {
      .product-tile .quickview {
        display: none; } }
    .product-tile .quickview .icon {
      margin: 0 auto; }
    .product-tile .quickview:hover {
      padding: 0; }
  .product-tile:hover .quickview {
    visibility: visible; }

.form-nav .nav-tabs .nav-link {
  color: #333; }

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
  background-color: #fff;
  color: #333; }

.nav-tabs .nav-link.active {
  background-color: transparent; }

@media (min-width: 992px) {
  .form-nav .nav-item > .nav-link {
    color: #333; } }

.breadcrumb-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  border-bottom: 1px solid #ddd; }
  .breadcrumb-bar .breadcrumb {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 0;
    padding: 0; }
    .breadcrumb-bar .breadcrumb .breadcrumb-item a {
      font-size: 0.75rem; }
    .breadcrumb-bar .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
      content: "";
      width: 8px;
      height: 8px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow.svg");
      margin: 7px 10px 0 0; }
    .breadcrumb-bar .breadcrumb .breadcrumb-item:last-child a {
      text-decoration: none;
      color: #ddd; }
    @media (max-width: 543.98px) {
      .breadcrumb-bar .breadcrumb .breadcrumb-item + .breadcrumb-item {
        display: none; }
      .breadcrumb-bar .breadcrumb .breadcrumb-item:nth-last-child(2) {
        display: block; } }

.veil {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .veil .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000; }

.spinner {
  width: 80px;
  height: 80px;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
          animation: sk-rotate 2s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px; }

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
          animation: sk-bounce 2s infinite ease-in-out; }

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

/* Colors Neuhaus */
/* Fonts Neuhaus */
.item-attributes {
  vertical-align: top;
  padding-left: 0; }

.line-item-attributes,
.line-item-option {
  font-size: 0.813rem;
  margin: 0; }

.line-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #222;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.313em; }

.line-item-pricing-info {
  margin-bottom: 0; }
  .line-item-pricing-info + .price {
    font-size: 1em;
    font-weight: bolder; }

.line-item-price-quantity-info {
  margin-top: 0.625em;
  border-top: 1px solid #ccc; }

.line-item-total-text {
  font-size: 0.813em; }

.pricing {
  font-size: 1em;
  font-weight: bolder; }

.item-image {
  height: 5.625em;
  width: 5.625em;
  margin-right: 0.938em;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .item-image img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    max-height: 5.625em; }

.non-adjusted-price {
  display: none; }

.line-item-promo {
  color: #19b200;
  font-size: 0.813em; }

.line-item-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.bundled-line-item + .bundled-line-item {
  margin-top: 0.625em; }

.bundle-includes {
  font-size: 0.813em;
  margin-bottom: 0.625em; }

.line-item-divider {
  margin: 0.625em -1.225em 0.625em -1.325em; }

.line-dotted {
  border-top: 0.063em dashed #ccc; }

.line-item-availability {
  font-size: 0.813rem; }

.product-line-item-details {
  overflow-y: auto; }

html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background, .modal-background-page, .modal-background-consent, header.flyout-open .modal-background-flyout,
  header.flyout-open .modal-background-menu, header.menu-open .modal-background-flyout,
  header.menu-open .modal-background-menu {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 3rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: 1.25rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, footer .container-footer-navigation .footer-navigation .footer-navigation-urls li a, .minicart .cart .sub-total-label,
.minicart .cart .sub-total, .line-item-total-price, .font-heading, .header-flyout-content-title {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy, .cart .remove-btn,
.cart .remove-btn-lg {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

.minicart {
  position: relative; }
  .minicart .cart h1 {
    font-size: 1.25rem;
    font-weight: 700; }
  .minicart .cart .minicart-footer {
    border-top: 1px solid #ddd; }
  .minicart .cart .sub-total-label,
  .minicart .cart .sub-total {
    font-size: 1.25rem;
    font-weight: 700; }
  .minicart .custom-product-properties {
    font-size: 1rem;
    margin-bottom: 0;
    margin-top: 0.5rem; }
  .minicart .popover {
    border-radius: 0;
    top: calc(100% + 28px);
    left: auto;
    right: 0;
    min-width: 23.44rem;
    max-width: 23.44rem;
    min-height: 22.7rem;
    display: none;
    font-family: "Plantin", serif;
    text-transform: none;
    letter-spacing: 0.01em; }
    .minicart .popover.empty {
      min-height: auto; }
    .minicart .popover::after {
      content: "";
      height: 1rem;
      width: 1rem;
      background: #fff;
      position: absolute;
      top: -0.5rem;
      right: 16px;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      z-index: 60;
      margin: auto; }
      @media (max-width: 1199.98px) {
        .minicart .popover::after {
          display: none; } }
    .minicart .popover.show {
      display: block; }
    .minicart .popover::before {
      content: "";
      height: 30px;
      width: 50px;
      display: block;
      position: absolute;
      top: -30px;
      right: 0; }
  .minicart .product-summary {
    max-height: 21.875em;
    overflow-y: auto;
    overflow-x: hidden; }
    .minicart .product-summary .product-card:not(:last-of-type) {
      border-bottom: 1px solid #ddd; }
    .minicart .product-summary .product-line-item .checkout-pname,
    .minicart .product-summary .product-line-item .checkout-pimg {
      display: none; }
  .minicart .product-line-item-details {
    overflow-y: visible; }
    .minicart .product-line-item-details .item-image {
      max-width: 100%; }

/* SFRA */
@media (min-width: 544px) and (max-width: 768.98px) {
  .hide-link-med {
    display: none; } }

@media (min-width: 769px) {
  .hide-no-link {
    display: none; } }

@media (max-width: 543.98px) {
  .hide-no-link {
    display: none; } }

/* Cart */
.line-item-total-price {
  font-size: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end; }
  @media (max-width: 768.98px) {
    .line-item-total-price {
      text-align: right; } }
  .line-item-total-price .price {
    margin-top: 44px;
    color: #7c655a; }
    .line-item-total-price .price .pricing {
      font-weight: 400; }
    .line-item-total-price .price .strike-through {
      color: #cf7c58;
      margin: 0 0 0.5rem; }

.cart .remove-btn,
.cart .remove-btn-lg {
  font-size: 0.875rem;
  color: #503223;
  text-decoration: underline;
  text-transform: unset; }
  .cart .remove-btn:hover,
  .cart .remove-btn-lg:hover {
    color: #503223;
    text-decoration: underline; }

.single-approaching-discount {
  background-color: rgba(25, 178, 0, 0.25);
  color: #19b200;
  margin-bottom: 0.5rem;
  padding: 20px; }
  .single-approaching-discount .icon {
    margin-right: 20px;
    min-width: 25px; }

header.sticky .minicart .popover {
  top: calc(100% + 40px); }
  header.sticky .minicart .popover::before {
    height: 40px;
    top: -40px; }

/* 20/16 */
.info-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: none;
  padding: 0;
  background: inherit; }
  .info-icon .icon {
    border-radius: 1.25rem;
    background-color: #00a1e0;
    width: 1.5625rem;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-weight: 600; }
  .info-icon .tooltip {
    position: absolute;
    bottom: 100%;
    padding: 0.312rem;
    border-radius: 0.1875rem;
    background-color: #444;
    color: #fff;
    font-size: 0.928rem;
    min-width: 20rem;
    max-width: 15rem;
    -webkit-transform: translate(-50%, -0.625em);
            transform: translate(-50%, -0.625em);
    left: 50%;
    margin-left: 4px;
    -webkit-animation: fade-in 0.5s linear forwards;
            animation: fade-in 0.5s linear forwards; }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .info-icon .tooltip::before {
      content: " ";
      position: absolute;
      display: block;
      height: 1.25em;
      /* 20/16 */
      left: 0;
      bottom: -1.25em;
      width: 100%; }
    .info-icon .tooltip::after {
      border-left: solid transparent 0.625em;
      /* 10/16 */
      border-right: solid transparent 0.625em;
      /* 10/16 */
      border-top: solid #444 0.625em;
      bottom: -0.625em;
      content: " ";
      height: 0;
      left: 50%;
      margin-left: -13px;
      position: absolute;
      width: 0; }

.grecaptcha-badge {
  visibility: hidden;
  left: -4px !important;
  width: 70px !important;
  overflow: hidden !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important; }

.grecaptcha-badge:hover {
  width: 256px !important; }

.invalid-feedback, .form-feedback {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em;
  font-size: 0.875rem;
  background-color: #ebd0d3;
  display: none;
  padding: 8px 25px;
  margin-top: unset;
  margin-bottom: 4px; }

.checkbox-wrapper,
.form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding: 0; }
  .checkbox-wrapper input[type="radio"],
  .checkbox-wrapper input[type="checkbox"],
  .form-check input[type="radio"],
  .form-check input[type="checkbox"] {
    position: absolute;
    top: -5px;
    left: 20px;
    height: 20px;
    width: 20px;
    -webkit-appearance: none; }
    .checkbox-wrapper input[type="radio"]:checked + .custom-checkbox-label::after,
    .checkbox-wrapper input[type="checkbox"]:checked + .custom-checkbox-label::after,
    .form-check input[type="radio"]:checked + .custom-checkbox-label::after,
    .form-check input[type="checkbox"]:checked + .custom-checkbox-label::after {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .checkbox-wrapper input[type="radio"]:focus,
    .checkbox-wrapper input[type="checkbox"]:focus,
    .form-check input[type="radio"]:focus,
    .form-check input[type="checkbox"]:focus {
      outline: none; }
  .checkbox-wrapper .form-check-label,
  .form-check .form-check-label {
    margin-left: 18px; }

.custom-checkbox-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  height: 20px;
  width: 20px; }
  .custom-checkbox-label:focus {
    outline: none; }

.custom-checkbox-label::before,
.custom-checkbox-label::after {
  pointer-events: none; }

.custom-checkbox-label::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: " ";
  height: 20px;
  width: 20px;
  border: solid 1px #a5a5a5;
  background: #fff; }

.custom-checkbox-label::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: " ";
  height: 12px;
  width: 12px;
  background: #503223;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease; }

input.form-control,
select.form-control,
textarea.form-control,
.selectric {
  color: #7c655a;
  padding: 0 1.25em;
  border: 1px solid #a5a5a5;
  height: 3.125em;
  border-radius: 0; }
  input.form-control:focus, input.form-control-wrapper.selectric-custom-select:focus,
  input.form-control.selectric .label:focus,
  select.form-control:focus,
  select.form-control-wrapper.selectric-custom-select:focus,
  select.form-control.selectric .label:focus,
  textarea.form-control:focus,
  textarea.form-control-wrapper.selectric-custom-select:focus,
  textarea.form-control.selectric .label:focus,
  .selectric:focus,
  .selectric-wrapper.selectric-custom-select:focus,
  .selectric.selectric .label:focus {
    color: #503223;
    border-color: currentColor;
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: none; }

textarea.form-control {
  height: 200px;
  min-height: 50px;
  padding-top: 1rem; }

.form-group .form-control-label,
.form-group label {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.form-group.is-invalid .invalid-feedback, .form-group.is-invalid .form-feedback {
  display: block; }

.custom-select {
  opacity: 0; }

.selectric-wrapper .custom-select {
  opacity: 1; }

.selectric-wrapper .selectric {
  background: transparent; }
  .selectric-wrapper .selectric .label {
    height: 100%;
    width: 100%;
    color: #7c655a;
    font-size: 1rem;
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-right: 1.5em; }
  .selectric-wrapper .selectric .icon,
  .selectric-wrapper .selectric .flag-icon {
    min-width: 20px; }

.selectric-wrapper.selectric-open {
  z-index: 1000; }
  .selectric-wrapper.selectric-open .selectric {
    border-color: #503223; }

.selectric-wrapper.selectric-invalid .selectric {
  border-color: #b14350; }

.selectric-wrapper.selectric-invalid ~ .invalid-feedback, .selectric-wrapper.selectric-invalid ~ .form-feedback {
  display: block; }

.selectric-wrapper .button {
  content: "";
  width: 16px;
  height: 16px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/icons/arrow.svg");
  top: 0;
  right: 20px;
  height: 100%;
  background-position: center;
  background-color: transparent; }
  .selectric-wrapper .button::after {
    border: none; }

.selectric-wrapper .selectric-items {
  z-index: 2;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #fff;
  border: 1px solid currentColor;
  border-top-color: #ddd;
  overflow: auto;
  top: calc(100% - 1px);
  width: 100% !important; }
  .selectric-wrapper .selectric-items li {
    font-size: 1rem;
    color: #7c655a;
    height: auto;
    min-height: 2.8125em;
    padding: 0 1.25em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: left;
    cursor: pointer; }
    .selectric-wrapper .selectric-items li:not(:first-child) {
      border-top: 1px solid #ddd; }
    .selectric-wrapper .selectric-items li:hover, .selectric-wrapper .selectric-items li.selected, .selectric-wrapper .selectric-items li.selected.highlighted {
      background-color: #efefef; }
    .selectric-wrapper .selectric-items li.highlighted {
      background-color: #f7f7f7; }
    .selectric-wrapper .selectric-items li.disabled {
      background: #fff;
      color: #a5a5a5;
      cursor: default;
      height: calc(2.8125em / 2); }
    .selectric-wrapper .selectric-items li img {
      width: 65px; }
    .selectric-wrapper .selectric-items li .icon,
    .selectric-wrapper .selectric-items li .flag-icon {
      min-width: 20px; }

/* Input type='number' reset */
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

/* Bootstrap override */
.form-control {
  border-radius: 0;
  color: #7c655a; }
  .form-control:focus {
    color: #7c655a; }
  .form-control::-webkit-input-placeholder {
    color: #7c655a;
    opacity: 0.75; }
  .form-control::-moz-placeholder {
    color: #7c655a;
    opacity: 0.75; }
  .form-control:-ms-input-placeholder {
    color: #7c655a;
    opacity: 0.75; }
  .form-control::-ms-input-placeholder {
    color: #7c655a;
    opacity: 0.75; }
  .form-control::placeholder {
    color: #7c655a;
    opacity: 0.75; }

.form-feedback {
  color: #b14350; }

.input-quantity {
  color: #503223;
  border: 1px solid #ddd;
  background-color: #efefef;
  min-width: 50px; }
  .input-quantity:focus {
    outline: none; }

.form-control:disabled, .form-control[readonly] {
  background-color: #efefef; }

.add-to-wishlist-alert {
  -webkit-animation: fade 5s linear forwards;
          animation: fade 5s linear forwards;
  -webkit-box-shadow: 1px 1px 5px grey;
          box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-wishlist-alert.show {
    display: block; }

.add-to-wish-list {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 10px;
  background-color: #efefef;
  border-radius: 30px; }
  @media (min-width: 992px) {
    .add-to-wish-list {
      top: 0;
      bottom: auto; } }

.product-detail .pdp-wishlist .add-to-wish-list {
  top: 0;
  bottom: auto;
  margin-right: 15px; }

@media (max-width: 991.98px) {
  .product-detail .pdp-wishlist.no-prop .add-to-wish-list {
    top: -6px; } }

.suggestions {
  display: block;
  position: absolute;
  background-color: #fff;
  right: 0;
  width: 100vw;
  z-index: 1080;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }
  @media (max-width: 991.98px) {
    .suggestions {
      height: calc(100vh - 158px);
      top: 158px;
      overflow-y: auto; } }
  @media (max-width: 991.98px) {
    .suggestions .suggestion-lists {
      width: 100%; } }
  @media (max-width: 991.98px) {
    .suggestions .suggestion-lists .suggestion-section:not(:last-child) {
      border-bottom: 1px solid #ddd;
      padding-bottom: 24px;
      margin-bottom: 24px; } }
  @media (min-width: 769px) {
    .suggestions .suggestion-lists .suggestion-section:not(:last-child) {
      border-right: 1px solid #ddd;
      padding-right: 48px;
      margin-right: 48px; } }
  .suggestions .suggestion-lists .suggestion-section ul {
    padding: 0;
    margin: 0; }
    .suggestions .suggestion-lists .suggestion-section ul li,
    .suggestions .suggestion-lists .suggestion-section ul .item {
      padding: 4px 0;
      list-style: none; }
  .suggestions .category-image,
  .suggestions .product-image {
    max-height: 75px;
    max-width: 75px; }
    @media (min-width: 769px) {
      .suggestions .category-image,
      .suggestions .product-image {
        max-height: 50px;
        max-width: 50px; } }
  .suggestions .product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.search-field-mobile {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 0;
  overflow: hidden;
  background: #fff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-top: 1px solid #ddd;
  z-index: 1; }
  .search-field-mobile .site-search {
    width: 100%;
    padding: 1rem 1rem 2rem; }
  .search-field-mobile.active {
    height: 83px; }

.slide-up {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  overflow: hidden;
  height: auto; }
  .slide-up.hide {
    max-height: 36px;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out; }

.site-search {
  width: 150px; }
  @media (min-width: 544px) {
    .site-search {
      margin-right: 0.5em; } }
  .site-search .search-field {
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    width: 100%;
    height: 35px !important;
    padding-bottom: 0; }
    .site-search .search-field:focus {
      -webkit-box-shadow: none;
              box-shadow: none;
      border-bottom: 1px solid #a5a5a5; }
    .site-search .search-field::-webkit-input-placeholder {
      color: #7c655a;
      opacity: 1;
      /* Firefox */ }
    .site-search .search-field::-moz-placeholder {
      color: #7c655a;
      opacity: 1;
      /* Firefox */ }
    .site-search .search-field:-ms-input-placeholder {
      color: #7c655a;
      opacity: 1;
      /* Firefox */ }
    .site-search .search-field::-ms-input-placeholder {
      color: #7c655a;
      opacity: 1;
      /* Firefox */ }
    .site-search .search-field::placeholder {
      color: #7c655a;
      opacity: 1;
      /* Firefox */ }
    .site-search .search-field::-ms-input-placeholder, .site-search .search-field::-ms-input-placeholder {
      color: #7c655a; }
  .site-search .fa-search {
    position: absolute;
    border: none;
    top: -0.3em;
    right: 0;
    padding: 0;
    background-color: transparent; }

header {
  border-bottom: 1px solid #ddd; }
  header .navbar {
    position: relative;
    z-index: 1080;
    height: 75px;
    background-color: #fff; }
    @media (max-width: 768.98px) {
      header .navbar .main-menu {
        overflow-y: scroll; } }
    @media (min-width: 769px) {
      header .navbar {
        height: auto; }
        header .navbar .main-menu {
          height: 75px; } }
  header .icon-container {
    width: 50px; }
    @media (max-width: 543.98px) {
      header .icon-container {
        width: 35px; } }
  header .icon-menu-mobile {
    width: 30px; }
  header .wishlist-count,
  header .minicart .minicart-quantity {
    position: absolute;
    background-color: #cf7c58;
    color: #fff;
    top: -18px;
    right: -4px;
    left: auto;
    height: 22px;
    min-width: 22px;
    padding-top: 4px;
    font-family: sans-serif;
    font-size: 0.75rem; }
  header .wishlist-count {
    border-radius: 50%;
    line-height: normal;
    display: inline-block;
    text-align: center; }
  header .logged-in-icons {
    position: relative; }
    header .logged-in-icons .user-message-name {
      padding-top: 5px;
      color: #7c655a;
      text-align: center;
      font-size: 0.75rem;
      position: absolute;
      white-space: nowrap; }
      header .logged-in-icons .user-message-name span {
        text-decoration: underline; }
  header.flyout-open .modal-background-flyout,
  header.flyout-open .modal-background-menu, header.menu-open .modal-background-flyout,
  header.menu-open .modal-background-menu {
    display: block !important;
    top: unset; }
  @media (min-width: 769px) {
    header .promotion-banners {
      width: 100%; }
    header.sticky .modal-background-menu {
      top: 75px; } }

body.scroll-up .promotion-banners,
body.scroll-down .promotion-banners {
  display: none; }

@media (max-width: 768.98px) {
  header.header-no-menu .content-container {
    padding-right: 0;
    padding-left: 0; }
    header.header-no-menu .content-container .content-message {
      border-top: 1px solid #ddd; } }

.catalog-label-new {
  font-family: "Flama", sans-serif;
  letter-spacing: 0.1em;
  background-color: #503223;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: white;
  font-size: 0.75rem;
  margin-bottom: 4px;
  height: 20px;
  margin-left: 10px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px; }

.header-flyout {
  position: relative; }
  .sticky .header-flyout .header-flyout-content {
    top: 60px; }
  .header-flyout-login {
    position: relative; }
  .header-flyout-content {
    border: 1px solid #ddd;
    display: none;
    z-index: 1080;
    position: absolute;
    top: 75px;
    background: #fff;
    left: -35%;
    min-width: 250px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .header-flyout-content::after {
      content: "";
      height: 1rem;
      width: 1rem;
      background: #fff;
      position: absolute;
      top: -0.5rem;
      right: 68px;
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      z-index: 60;
      margin: auto; }
      @media (max-width: 1199.98px) {
        .header-flyout-content::after {
          display: none; } }
    .header-flyout-content-list li {
      border-bottom: 1px solid #ddd; }
    @media (min-width: 769px) {
      .header-flyout-content {
        top: 48px;
        width: 410px; } }
    .header-flyout-content .forgot-password a {
      font-size: 0.875rem; }
    .header-flyout-content .remember-me-label {
      color: #7c655a; }
    .header-flyout-content-close {
      cursor: pointer;
      background: transparent;
      border: none;
      z-index: 60;
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
      right: 10px;
      top: 10px;
      position: absolute; }
    .header-flyout-content-title {
      font-size: 1rem; }
    .header-flyout-content .login-form {
      min-width: 300px; }
      .header-flyout-content .login-form input {
        font-size: 0.875rem; }
      .header-flyout-content .login-form .form-group:nth-child(2) {
        margin-bottom: 8px; }
      .header-flyout-content .login-form .form-check {
        font-size: 0.75rem; }
    .header-flyout-content ul {
      margin: 0;
      padding: 0; }
      .header-flyout-content ul li {
        list-style: none; }
        .header-flyout-content ul li:last-child {
          margin: 0; }
        .header-flyout-content ul li a {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .header-flyout-content ul li a:hover {
            background-color: #efefef; }
    .header-flyout-content .forgotpw-modal {
      display: none; }
  .header-flyout--loggedin .header-flyout-content {
    min-width: 280px;
    top: 53px; }

.mobile-flyout .header-flyout-content {
  left: auto;
  right: -15px;
  width: 100vw;
  min-width: 0;
  -webkit-transform: none;
          transform: none;
  overflow-y: scroll;
  max-height: calc(100vh - 75px); }
  .mobile-flyout .header-flyout-content .login-form {
    min-width: 0;
    width: 100%; }

.transparant-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0; }

.menu-open .transparant-overlay {
  z-index: 20; }

.flyout-open .transparant-overlay {
  z-index: 40; }

header {
  top: 0;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  will-change: top;
  position: sticky;
  z-index: 80; }
  header .header-icons {
    position: absolute;
    right: 0; }
  header.sticky {
    position: sticky; }
    header.sticky .header-icons {
      height: 75px; }
    header.sticky .logo {
      visibility: hidden; }

#maincontent {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out; }

.scroll-down header:not(.flyout-open) {
  top: -75px; }

@media (min-width: 769px) {
  header {
    position: relative; }
    header .header-icons {
      top: 0; }
    header .main-menu {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      left: 50%;
      z-index: 20; }
      header .main-menu .nav-item > .dropdown-menu {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        left: 50%; }
    header .sticky-logo {
      display: none; }
    header .left-0 {
      left: 0; }
    header .logo {
      height: 80px; }
    header.sticky {
      -webkit-animation: slideIn ease-in-out 0.5s;
              animation: slideIn ease-in-out 0.5s;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards; }
  @-webkit-keyframes slideIn {
    0% {
      top: -300px; }
    100% {
      top: calc(-80px - 75px); } }
  @keyframes slideIn {
    0% {
      top: -300px; }
    100% {
      top: calc(-80px - 75px); } }
      header.sticky .header-icons {
        top: 80px; }
      header.sticky .main-menu {
        left: 0;
        -webkit-transform: none;
                transform: none; }
        header.sticky .main-menu .nav-item > .dropdown-menu {
          -webkit-transform: none;
                  transform: none;
          left: -15px; } }
      @media (min-width: 769px) and (min-width: 992px) {
        header.sticky .main-menu {
          left: 85px; }
          header.sticky .main-menu .nav-item > .dropdown-menu {
            left: -100px; }
          header.sticky .main-menu .sticky-logo {
            display: inline-block;
            position: absolute;
            left: -65px; } }
      @media (min-width: 769px) and (min-width: 1200px) {
        header.sticky .main-menu .nav-item > .dropdown-menu {
          left: -125px; } }

@media (min-width: 769px) {
  .scroll-down header {
    -webkit-transform: none;
            transform: none; } }

/* PageHeaderNoMenu not sticky */
header.header-no-menu {
  position: static; }

.scroll-down header.header-no-menu {
  -webkit-transform: none;
          transform: none; }

.email-signup-container {
  background-color: #ddd;
  padding: 1.5rem 0; }
  @media (min-width: 769px) {
    .email-signup-container {
      padding: 3rem 0; } }

@media (max-width: 543.98px) {
  input.email-description-input.form-control {
    width: 100%; } }

a.btn-link,
.btn-link {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em;
  background-color: transparent;
  border: none;
  height: auto;
  text-decoration: none;
  cursor: pointer; }
  a.btn-link:active, a.btn-link:focus,
  .btn-link:active,
  .btn-link:focus {
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none; }
  a.btn-link:hover,
  .btn-link:hover {
    text-decoration: underline;
    color: inherit; }

.btn, .adyen-checkout__button--pay {
  border-radius: 0;
  font-family: "Flama", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  letter-spacing: 0.1em;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .btn .text, .adyen-checkout__button--pay .text,
  .btn .pd-text,
  .adyen-checkout__button--pay .pd-text {
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    will-change: transform;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .btn .text::after, .adyen-checkout__button--pay .text::after,
  .btn .pd-text::after,
  .adyen-checkout__button--pay .pd-text::after {
    -webkit-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    will-change: right, opacity;
    opacity: 0;
    position: absolute;
    right: 0;
    top: calc(50% - 0.5rem); }
  .btn .pd-arrow-icon, .adyen-checkout__button--pay .pd-arrow-icon {
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    will-change: right, opacity;
    opacity: 0;
    position: absolute;
    right: 0.15em; }
  .btn:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover, .adyen-checkout__button--pay:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
    .btn:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .text, .adyen-checkout__button--pay:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .text,
    .btn:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .pd-text,
    .adyen-checkout__button--pay:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .pd-text {
      -webkit-transform: translate3d(-0.75rem, 0, 0);
              transform: translate3d(-0.75rem, 0, 0); }
    .btn:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .text::after, .adyen-checkout__button--pay:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .text::after,
    .btn:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .pd-text::after,
    .adyen-checkout__button--pay:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .pd-text::after {
      opacity: 1;
      right: -1.75rem; }
    .btn:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .pd-arrow-icon, .adyen-checkout__button--pay:not(.btn-alternate-secondary):not(.btn-alternate-primary):not(.btn-alternate-white):hover:not(:disabled):not(.disabled) .pd-arrow-icon {
      opacity: 1;
      right: 0.15em; }
  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #503223;
    border: none; }
    .btn-primary .text::after, .btn-primary:hover .text::after, .btn-primary:active .text::after, .btn-primary:focus .text::after, .btn-primary:not(:disabled):not(.disabled):active .text::after {
      content: "";
      width: 15px;
      height: 15px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow-white.svg"); }
  .btn-secondary, .btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #cf7c58;
    border: none; }
    .btn-secondary .text::after, .btn-secondary:hover .text::after, .btn-secondary:active .text::after, .btn-secondary:focus .text::after, .btn-secondary:not(:disabled):not(.disabled):active .text::after {
      content: "";
      width: 15px;
      height: 15px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow-white.svg"); }
  .btn-white, .btn-white:hover, .btn-white:active, .btn-white:focus, .btn-white:not(:disabled):not(.disabled):active {
    background-color: #fff;
    color: #503223;
    border: none; }
    .btn-white .text::after, .btn-white:hover .text::after, .btn-white:active .text::after, .btn-white:focus .text::after, .btn-white:not(:disabled):not(.disabled):active .text::after {
      content: "";
      width: 15px;
      height: 15px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/arrow.svg"); }
  .btn.disabled, .disabled.adyen-checkout__button--pay, .btn:disabled, .adyen-checkout__button--pay:disabled {
    opacity: 0.5; }
  .btn.btn-collapsed, .btn-collapsed.adyen-checkout__button--pay {
    height: auto; }
    .btn.btn-collapsed, .btn-collapsed.adyen-checkout__button--pay, .btn.btn-collapsed:hover, .btn-collapsed.adyen-checkout__button--pay:hover {
      padding-left: 0;
      padding-right: 0; }
    .btn.btn-collapsed .collapse-icon, .btn-collapsed.adyen-checkout__button--pay .collapse-icon {
      width: 15px;
      background-position: center;
      background-image: url("../images/icons/dash.svg"); }
    .btn.btn-collapsed .toggle-icon, .btn-collapsed.adyen-checkout__button--pay .toggle-icon {
      background-position: center;
      background-image: url("../images/icons/arrow.svg");
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
    .btn.btn-collapsed.collapsed .collapse-icon, .btn-collapsed.collapsed.adyen-checkout__button--pay .collapse-icon {
      background-image: url("../images/icons/add.svg"); }
    .btn.btn-collapsed.collapsed .toggle-icon, .btn-collapsed.collapsed.adyen-checkout__button--pay .toggle-icon {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .btn.btn-collapsed:active, .btn-collapsed.adyen-checkout__button--pay:active, .btn.btn-collapsed:focus, .btn-collapsed.adyen-checkout__button--pay:focus, .btn.btn-collapsed:hover, .btn-collapsed.adyen-checkout__button--pay:hover {
      color: #503223;
      outline: none;
      -webkit-box-shadow: none;
              box-shadow: none; }
    @media (min-width: 769px) {
      .btn.btn-collapsed-mobile, .btn-collapsed-mobile.adyen-checkout__button--pay {
        pointer-events: none; }
        .btn.btn-collapsed-mobile:hover, .btn-collapsed-mobile.adyen-checkout__button--pay:hover {
          cursor: default; } }
  .btn-quantity {
    min-width: 50px;
    background-color: #fff;
    border: 1px solid #ddd; }
    .btn-quantity:active, .btn-quantity:focus, .btn-quantity:hover {
      outline: none; }
    .btn-quantity.disabled .icon {
      opacity: 0.5; }

.btn-outline-primary, .btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary:focus, .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #503223;
  background-color: transparent; }
  .btn-outline-primary .text::after, .btn-outline-primary:hover .text::after, .btn-outline-primary:active .text::after, .btn-outline-primary:focus .text::after, .btn-outline-primary:not(:disabled):not(.disabled):active .text::after {
    content: "";
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow.svg"); }

.btn-outline-secondary, .btn-outline-secondary:hover, .btn-outline-secondary:active, .btn-outline-secondary:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: #cf7c58;
  background-color: transparent; }
  .btn-outline-secondary .text::after, .btn-outline-secondary:hover .text::after, .btn-outline-secondary:active .text::after, .btn-outline-secondary:focus .text::after, .btn-outline-secondary:not(:disabled):not(.disabled):active .text::after {
    content: "";
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow-secondary.svg"); }

.btn-outline-white, .btn-outline-white:hover, .btn-outline-white:active, .btn-outline-white:focus, .btn-outline-white:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff; }
  .btn-outline-white .text::after, .btn-outline-white:hover .text::after, .btn-outline-white:active .text::after, .btn-outline-white:focus .text::after, .btn-outline-white:not(:disabled):not(.disabled):active .text::after {
    content: "";
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow-white.svg"); }

.btn-back {
  color: #fff;
  background-color: #7c655a;
  height: 33px; }
  .btn-back, .btn-back:hover {
    padding: 0 15px; }
  .btn-back:active, .btn-back:focus, .btn-back:hover {
    color: #fff; }
  .btn-back::before {
    content: "";
    width: 11px;
    height: 11px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow-white.svg");
    margin-right: 5px; }

.btn-alternate-primary, .btn-alternate-secondary, .btn-alternate-white {
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  padding-left: 0;
  padding-right: 0; }
  .btn-alternate-primary::after, .btn-alternate-secondary::after, .btn-alternate-white::after {
    content: "";
    width: 100%;
    height: 1px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0; }
  .btn-alternate-primary:hover, .btn-alternate-secondary:hover, .btn-alternate-white:hover {
    padding-left: 0;
    padding-right: 1.75rem; }
    .btn-alternate-primary:hover .pd-text,
    .btn-alternate-primary:hover .text, .btn-alternate-secondary:hover .pd-text,
    .btn-alternate-secondary:hover .text, .btn-alternate-white:hover .pd-text,
    .btn-alternate-white:hover .text {
      -webkit-transform: unset;
              transform: unset;
      position: relative; }
    .btn-alternate-primary:hover .pd-text::after,
    .btn-alternate-primary:hover .text::after, .btn-alternate-secondary:hover .pd-text::after,
    .btn-alternate-secondary:hover .text::after, .btn-alternate-white:hover .pd-text::after,
    .btn-alternate-white:hover .text::after {
      opacity: 1;
      right: -1.75rem; }

.btn-alternate-primary, .btn-alternate-primary:hover {
  color: #503223; }
  .btn-alternate-primary .text::after, .btn-alternate-primary:hover .text::after {
    content: "";
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow.svg"); }
  .btn-alternate-primary::after, .btn-alternate-primary:hover::after {
    background-color: #503223; }

.btn-alternate-secondary, .btn-alternate-secondary:hover {
  color: #cf7c58; }
  .btn-alternate-secondary .text::after, .btn-alternate-secondary:hover .text::after {
    content: "";
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow-secondary.svg"); }
  .btn-alternate-secondary::after, .btn-alternate-secondary:hover::after {
    background-color: #cf7c58; }

.btn-alternate-white, .btn-alternate-white:hover {
  color: #fff; }
  .btn-alternate-white .text::after, .btn-alternate-white:hover .text::after {
    content: "";
    width: 15px;
    height: 15px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../images/icons/arrow-white.svg"); }
  .btn-alternate-white::after, .btn-alternate-white:hover::after {
    background-color: #fff; }

.no-arrow:hover {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.chevron {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px; }

.chevron::after {
  content: "";
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  right: 6px;
  top: 4px; }

.adyen-checkout__button--pay {
  color: #fff;
  background-color: #cf7c58 !important;
  border: none !important;
  border-radius: 0 !important; }
  .adyen-checkout__button--pay:hover {
    color: #fff !important; }

.remove-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  background-color: #efefef;
  border-radius: 30px; }

.icon {
  height: 20px;
  width: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* Social : BEGIN */
  /* Social : END */ }
  .icon-cart {
    background-image: url("../images/icons/cart.svg"); }
    .icon-cart-active {
      background-image: url("../images/icons/cart-active.svg"); }
    .icon-cart-white {
      background-image: url("../images/icons/cart-white.svg"); }
  .icon-user {
    background-image: url("../images/icons/user.svg"); }
    .icon-user-active {
      background-image: url("../images/icons/user-active.svg"); }
  .icon-wishlist {
    background-image: url("../images/icons/wishlist.svg"); }
    .icon-wishlist-active {
      background-image: url("../images/icons/wishlist-active.svg"); }
    .icon-wishlist-add {
      background-image: url("../images/icons/wishlist-add.svg"); }
    .icon-wishlist-added {
      background-image: url("../images/icons/wishlist-added.svg"); }
  .icon-search {
    background-image: url("../images/icons/search.svg"); }
  .icon-star {
    background-image: url("../images/icons/star.svg"); }
  .icon-present {
    background-image: url("../images/icons/present.svg"); }
  .icon-label {
    background-image: url("../images/icons/label.svg"); }
  .icon-envelop {
    background-image: url("../images/icons/envelop.svg"); }
    .icon-envelop-xl {
      width: 75px;
      height: 50px; }
  .icon-truck {
    background-image: url("../images/icons/truck.svg"); }
    .icon-truck-red {
      background-image: url("../images/icons/truck-red.svg"); }
    .icon-truck-green {
      background-image: url("../images/icons/truck-green.svg"); }
  .icon-lock {
    background-image: url("../images/icons/lock.svg"); }
  .icon-royal-warrant {
    background-image: url("../images/icons/royal-warrant.svg"); }
  .icon-add {
    background-image: url("../images/icons/add.svg"); }
    .icon-add-white {
      background-image: url("../images/icons/add-white.svg"); }
  .icon-dash {
    background-image: url("../images/icons/dash.svg"); }
  .icon-logo-dated {
    background-image: url("../images/icons/logo-dated.svg"); }
  .icon-logo-word {
    background-image: url("../images/icons/logo-word.svg"); }
  .icon-logo-circle {
    background-image: url("../images/icons/logo-circle.svg"); }
  .icon-menu-mobile {
    background-image: url("../images/icons/menu-mobile.svg"); }
  .icon-cross {
    background-image: url("../images/icons/cross.svg"); }
    .icon-cross-white {
      background-image: url("../images/icons/cross-white.svg"); }
    .icon-cross-dark-red {
      background-image: url("../images/icons/cross-dark-red.svg"); }
  .icon-border-rounded-white {
    border: 2px solid white;
    border-radius: 100%; }
  .icon-border-rounded-dark-red {
    border: 2px solid #5c232a;
    border-radius: 100%; }
  .icon-store {
    background-image: url("../images/icons/store.svg"); }
    .icon-store-white {
      background-image: url("../images/icons/store-white.svg"); }
  .icon-location {
    background-image: url("../images/icons/location.svg"); }
    .icon-location-white {
      background-image: url("../images/icons/location-white.svg"); }
  .icon-package {
    background-image: url("../images/icons/package.svg"); }
    .icon-package-white {
      background-image: url("../images/icons/package-white.svg"); }
    .icon-package-green {
      background-image: url("../images/icons/package-green.svg"); }
  .icon-id {
    background-image: url("../images/icons/id.svg"); }
    .icon-id-white {
      background-image: url("../images/icons/id-white.svg"); }
  .icon-creditcard {
    background-image: url("../images/icons/creditcard.svg"); }
    .icon-creditcard-white {
      background-image: url("../images/icons/creditcard-white.svg"); }
  .icon-contact {
    background-image: url("../images/icons/contact.svg"); }
    .icon-contact-white {
      background-image: url("../images/icons/contact-white.svg"); }
  .icon-grid {
    background-image: url("../images/icons/grid.svg"); }
    .icon-grid-white {
      background-image: url("../images/icons/grid-white.svg"); }
  .icon-customer-service {
    background-image: url("../images/icons/customer-service.svg"); }
  .icon-customer-service-alt {
    background-image: url("../images/icons/customer-service-alt.svg"); }
  .icon-arrow-up, .icon-arrow-left, .icon-arrow-right, .icon-arrow-down, .icon-arrow-white-up, .icon-arrow-white-left, .icon-arrow-white-right, .icon-arrow-white-down {
    background-position: center;
    width: 20px; }
  .icon-arrow-left, .icon-arrow-white-left {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .icon-arrow-right, .icon-arrow-white-right {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .icon-arrow-down, .icon-arrow-white-down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .icon-arrow-white {
    background-image: url("../images/icons/arrow-white.svg"); }
    .icon-arrow-white-up, .icon-arrow-white-left, .icon-arrow-white-right, .icon-arrow-white-down {
      background-image: url("../images/icons/arrow-white.svg"); }
  .icon-arrow-up, .icon-arrow-left, .icon-arrow-right, .icon-arrow-down {
    background-image: url("../images/icons/arrow.svg"); }
  .icon-information-circle, .icon-info {
    background-image: url("../images/icons/info.svg"); }
  .icon-instagram {
    background-image: url("../images/icons/instagram.svg"); }
  .icon-facebook {
    background-image: url("../images/icons/facebook.svg"); }
    .icon-facebook-white {
      background-image: url("../images/icons/facebook-white.svg"); }
  .icon-twitter-white {
    background-image: url("../images/icons/twitter-white.svg"); }
  .icon-mail-white {
    background-image: url("../images/icons/mail-white.svg"); }
  .icon-youtube {
    background-image: url("../images/icons/youtube.svg"); }
  .icon-instagram-circle {
    background-image: url("../images/icons/instagram-circle.svg"); }
  .icon-facebook-circle {
    background-image: url("../images/icons/facebook-circle.svg"); }
  .icon-youtube-circle {
    background-image: url("../images/icons/youtube-circle.svg"); }
  .icon-facebook-color {
    background-image: url("../images/icons/facebook.png"); }
  .icon-google-color {
    background-image: url("../images/icons/google.png"); }
  .icon-page {
    background-image: url("../images/icons/page.svg"); }
  .icon-leaves {
    background-image: url("../images/icons/leaves.svg"); }
  .icon-list {
    background-image: url("../images/icons/list.svg"); }
  .icon-box {
    background-image: url("../images/icons/box.svg"); }
  .icon-checkmark {
    background-image: url("../images/icons/checkmark.svg"); }
  .icon-check {
    background-image: url("../images/icons/check.svg"); }
  .icon-mapsmarker {
    background-image: url("../images/icons/mapsmarker.svg"); }
  .icon-current-location {
    background-image: url("../images/icons/current-location.svg"); }
  .icon-ice-cream {
    background-image: url("../images/icons/ice-cream.svg"); }
  .icon-id {
    background-image: url("../images/icons/id.svg"); }
    .icon-id-white {
      background-image: url("../images/icons/id-white.svg"); }
  .icon-package {
    background-image: url("../images/icons/package.svg"); }
    .icon-package-white {
      background-image: url("../images/icons/package-white.svg"); }
  .icon-creditcard {
    background-image: url("../images/icons/creditcard.svg"); }
    .icon-creditcard-white {
      background-image: url("../images/icons/creditcard-white.svg"); }
  .icon-grid {
    background-image: url("../images/icons/grid.svg"); }
    .icon-grid-white {
      background-image: url("../images/icons/grid-white.svg"); }
  .icon-logo-circle {
    background-image: url("../images/icons/logo-circle.svg"); }
    .icon-logo-circle-white {
      background-image: url("../images/icons/logo-circle-white.svg"); }
  .icon-wishlist {
    background-image: url("../images/icons/wishlist.svg"); }
    .icon-wishlist-white {
      background-image: url("../images/icons/wishlist-white.svg"); }
  .icon-contact {
    background-image: url("../images/icons/contact.svg"); }
    .icon-contact-white {
      background-image: url("../images/icons/contact-white.svg"); }
  .icon-direction {
    background-image: url("../images/icons/direction.svg"); }
  .icon-storefront {
    background-image: url("../images/icons/storefront.svg"); }
  .icon-warning {
    background-image: url("../images/icons/warning.svg"); }
    .icon-warning-xl {
      width: 75px;
      height: 70px; }
  .icon-extra-small {
    height: 7px;
    width: 7px; }
  .icon-small {
    height: 15px;
    width: 15px; }
  .icon-sm {
    height: 20px;
    width: 20px; }
  .icon-medium {
    height: 25px;
    width: 25px;
    min-width: 25px;
    min-height: 25px; }
  .icon-large {
    height: 45px;
    width: 45px; }

.consent-container .cookie-content span {
  cursor: pointer;
  text-decoration: underline; }

.consent-container .cookie-button button,
.consent-container .save-preferences button {
  height: 100%; }
  @media (min-width: 769px) {
    .consent-container .cookie-button button,
    .consent-container .save-preferences button {
      min-width: -webkit-min-content;
      min-width: -moz-min-content;
      min-width: min-content; } }

.consent-container .save-preferences .btn-link {
  font-size: 0.875rem;
  text-decoration: underline; }

.consent-container .adaptcookies-container {
  height: 100vh; }
  @media (min-width: 769px) {
    .consent-container .adaptcookies-container {
      height: auto; } }

.not-found-page hr {
  border-top: 1px solid #ddd; }

.not-found-page .image-wrapper {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }
  @media (min-width: 544px) {
    .not-found-page .image-wrapper {
      height: 375px; } }
  @media (min-width: 992px) {
    .not-found-page .image-wrapper {
      height: 150px; } }
  @media (min-width: 1440px) {
    .not-found-page .image-wrapper {
      height: 200px; } }

@media (min-width: 769px) {
  .not-found-page .page-subtitle {
    font-size: 1.25rem; }
  .not-found-page h2 {
    font-size: calc(1.425rem + 2.1vw); } }
  @media (min-width: 769px) and (min-width: 1200px) {
    .not-found-page h2 {
      font-size: 3rem; } }

@media (min-width: 769px) {
  .not-found-page .search-field {
    width: 50%; } }

@media (min-width: 992px) {
  .not-found-page .search-field {
    width: 400px; } }

.errorpage-header header .navbar {
  height: auto; }

.become-insider-container .form-control-label {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.become-insider-container .icon-medium {
  min-width: 25px; }

.become-insider-container .insider-content-tile {
  border-bottom: 1px solid #ddd; }
  @media (min-width: 769px) {
    .become-insider-container .insider-content-tile {
      border: none; } }

.become-insider-container .checkbox-wrapper a {
  color: #7c655a; }

.modal[style*="display: block"] {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (min-width: 544px) {
  .modal .modal-dialog {
    max-width: 550px; } }

.modal .modal-content {
  border-radius: 0; }
  .modal .modal-content .modal-header {
    border-bottom: none;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding: calc(1rem + 15px);
    padding-bottom: 0; }
    .modal .modal-content .modal-header .close {
      content: "";
      width: 20px;
      height: 20px;
      -webkit-transform: rotate(0);
              transform: rotate(0);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("../images/icons/cross.svg");
      width: 20px;
      height: 20px;
      opacity: 1;
      padding: 0;
      margin: 0; }
      .modal .modal-content .modal-header .close span {
        display: none; }
      .modal .modal-content .modal-header .close:focus {
        outline: none; }
  .modal .modal-content .modal-footer {
    border-top: none;
    border-radius: 0; }
